<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <div>
    <v-row class="d-flex my-auto"> 
        <v-col class="col1 pa-10 justify-center" cols="12" md="6">
          <v-img min-width="320" width="100%" height="auto" src="../assets/checkoutimg.png"></v-img>
        </v-col>

        <!-- <v-col class="col1xs pa-10 hidden-sm-and-up" cols="12" md="5">
          <v-card flat class="">
          <img width="80%" height="auto" src="../assets/checkoutimg.png">
          </v-card>
        </v-col> -->
        
        <v-col class="col2" cols="12" md="6">
          <v-card min-width="250" class="col3 mx-5" flat>
            <v-card-title class="px-0 mb-5">
              <h1 class="hidden-xs-only" id="h2">How would like to get your order?</h1>
              <h1 class="hidden-sm-and-up" id="h2xs">How would like to<br> get your order?</h1>
            </v-card-title>

            <v-form 
            ref="form"
            lazy-validation>

              <v-row class="d-flex">
                <v-col class="align-stretch">
                <p id="p">First Name</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="firstName"
                  required>
                </v-text-field>
                </v-col>
                
                <v-col class="align-stretch hidden-xs-only">
                <p id="p">Last Name</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="lastName"
                  required
                ></v-text-field>
                </v-col>
              </v-row>

              <div class="align-stretch hidden-sm-and-up">
                <p id="p">Last Name</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="lastName"
                  required
                ></v-text-field>
                </div>

              <div>
                <p id="p">Street Address</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="streetAddress"
                  required
                ></v-text-field>

              </div>

              <div class="hidden-xs-only">
                <v-row>
                  <v-col cols="6">
                    <p id="p">Town / City</p>
                    <v-text-field
                      dense
                      class="my-auto"
                      outlined
                      v-model="city"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <p id="p">Number</p>
                    <v-text-field
                      dense
                      class="my-auto"
                      outlined
                      v-model="number"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <p id="p">Post Code</p>
                    <v-text-field
                      class="my-auto"
                      dense
                      outlined
                      v-model="postcode"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div class="hidden-sm-and-up">
                <p id="p">Town / City</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="city"
                  required
                ></v-text-field>
              </div>

              <div class="hidden-sm-and-up">
                <p id="p">Number</p>
                <v-text-field
                  dense
                  class="my-auto"
                  outlined
                  v-model="number"
                  required
                ></v-text-field>
              </div>

              <div class="hidden-sm-and-up">
                <p id="p">Post Code</p>
                <v-text-field
                  class="my-auto"
                  dense
                  outlined
                  v-model="postcode"
                  required
                ></v-text-field>
              </div>

              <div>
                <p id="p">Country</p>
                <v-select
                  class="my-auto"
                  dense
                  outlined
                  v-model="country"
                  required
                ></v-select>

              </div>

              <div>
                <p id="p">Phone</p>
                <v-text-field
                  class="my-auto"
                  prepend-inner-icon="mdi-phone-in-talk"
                  outlined
                  v-model="phone"
                  required
                ></v-text-field>

              </div>

              <div>
                <p id="p">Email</p>
                <v-text-field
                  class="my-auto"
                  prepend-inner-icon="mdi-email-outline"
                  outlined
                  v-model="email"
                  required
                ></v-text-field>

              </div>

              <div>
                  <v-col class="d-flex justify-center pa-0 mt-10">
                    <v-btn @click="payment" block id="button1">Continue Payment</v-btn>
                  </v-col>
              </div>

              <div class="mt-2">
                <v-btn id="button2" plain>
                  <v-icon left>mdi-arrow-left</v-icon>
                  Back To Cart
                </v-btn>

                <div class="mt-2">
                  <v-row>
                    <v-col>
                      <v-card flat>
                      <p>Shipping</p>
                      <hr id="hr">
                      </v-card>
                    </v-col>

                    <v-col>
                      <v-card flat>
                      <p id="p1">Payment</p>
                      <hr id="hr1">
                      </v-card>
                    </v-col>

                    <v-col>
                      
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-col>       
    </v-row>
    </div>


  </div>
</template>

<script>
import header from '../components/Header.vue'

export default {
  name: 'account-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      firstName:"",
      lastName:"",
      streetAddress:"",
      city:"",
      number:"",
      postcode:"",
      country:"",
      phone:"",
      email:"", 
    }
  },

  methods : {

    payment(){
      this.$router.push('/payment')
    },

  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

#button{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
}

#button1{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: black;
  letter-spacing: normal;
  color: white;
}

#button2{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 15px;
  letter-spacing: normal;
  padding:5px 5px 5px 0px;
}

#name{
  margin-top: 2vh;
}

#p{
  font-size: 15px;
  margin-bottom: 2px;
}

#p1{
  color: #D9D9D9;
}

.mb1{
  margin-bottom: 10vh;
}

#h1{
  letter-spacing: 1vh;
  font-size: 35px;
  color: white;
}

#h2{
  font-size: 25px;
  font-weight:bold;
}

#h2xs{
  font-size: 20px;
  font-weight:bold;
}

#h3{
  font-size: 35px;
  font-weight: 900;
}

#h4{
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  /* font-family: inter; */
  color: rgba(0, 0, 0, 0.6);
}

#hr{
  height:10px;
  border-radius: 10px;
  background-color: black;
}

#hr1{
  height:10px;
  border-radius: 10px;
  background-color: #D9D9D9;
}

#border{
  border-radius: 10px;
}

.bg{
  background-image: url(../assets/checkoutimg.png);
  
}

.col1{
  display:flex;
  /* flex-basis:40%; */
  /* height:100vh;  */
  min-width: 350px;
}

.col1xs{
  display:flex;
  /* flex-basis:40%; */
}

.col2{
  display:flex;
  /* flex-basis:60%; */
  height:100vh;
  padding:25px 15px;
}

.col3{
  flex-grow:1;
}

.col4{
  display: inline;
}

.navBar{
  /* font-family: inter; */
  font-size: 30px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  /* background-color: rgba(0, 0, 0, 0.82) ; */
  color: black;
}

ul{
  padding:0px;
}

li{
  display: inline;
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-size: 20px;
}

li a{
  color:white;
  text-decoration:none;
}

a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 3px;
  width:0;
  left: 0;
  bottom: -4px;
  transition: 0.3s ease;
}

a:hover{
  color: #FFB84C;
}

a:hover:after{
  width:100%;
}

</style>