<template>
	<div class="body fill-height">
		<div>
			<Header></Header>
		</div>

		<!-- <div class="hidden-md-and-up">
			<img class="imgLogo2" width='450' height='100' src="../assets/logo2.png">
		</div>

		<div class="hidden-sm-and-down">
			<img class="imgLogo" width='450' height='100' src="../assets/logo2.png">
		</div> -->

		<div class="hidden-sm-and-down">
			<v-toolbar flat class="d-flex justify-center my-3">
				<v-toolbar-items flat class="hidden-md-and-down">
					<ul class="navBar2">
          <li class="li-lg"><v-btn id="fs-lg" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('culture')" text><a href='#'>Culture</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>

				<v-toolbar-items flat class="d-none d-md-flex d-lg-none">
					<ul class="navBar2">
          <li class="li-md"><v-btn id="fs-md" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('culture')" text><a href='#'>Ready To Wear</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>
        
			</v-toolbar>
		</div>

    <div class="mt-15">
      <v-card flat class="text-center ">
        <p class="text-no-wrap" style="font-size: 64px;" id="fhome">About Us</p>
        <p class="text-no-wrap" id="fhome2">Between passion and craftmanship</p>
      </v-card>
    </div>

		<div class="mt-12 mb-15">
      <v-row class="justify-center">
        <v-col cols="4" sm="4" md="3" lg="3">
        <v-card flat class="mx-auto" max-width="460" min-width="200"> 
          <v-img width="100%" max-height="570" min-height="200" :src="aboutSettingsdata.img1">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="4" sm="4" md="3" lg="3">
        <v-card flat class="mx-auto" max-width="460" min-width="200">
          <v-img width="100%" max-height="570" min-height="200" :src="aboutSettingsdata.img2">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="4" sm="4" md="3" lg="3">
        <v-card flat class="mx-auto" max-width="460" min-width="200">
          <v-img width="100%" max-height="570" min-height="200" :src="aboutSettingsdata.img3">
          </v-img>
        </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-row id="hrow" class="">
          <v-col class="" cols="11" sm="12" md="4" lg="5">
            <v-card flat class="d-flex align-center fill-height mx-auto" min-width="350" max-width="570">
              <v-img class="" width="100%" min-height="570" :src="aboutSettingsdata.img4"></v-img>
            </v-card>
          </v-col>

          <v-col class="d-flex justify-sm-center justify-center justify-md-center justify-lg-end " cols="12" sm="12" md="7" lg="5">
            <v-card flat id="hcard2" class=" " min-width="365" max-width="570">
              <div class="mb-15">
              <p class="pa-3 mx-auto" id="fhome">{{ aboutSettingsdata.title1 }}</p>
              </div>

              <div>
              <p class="pa-3 ma-0 text-justify" id="fhome2">{{ aboutSettingsdata.desc1 }}</p>
              </div>
            </v-card>
          </v-col>
      </v-row>
    </div>

		<div class="mt-15">
      <v-row id="hrow" class="">
          <v-col class="d-flex justify-center justify-sm-center justify-md-center justify-lg-center justify-xl-center" cols="12" sm="12" md="7" lg="6">
            <v-card flat id="hcard2" class="align-start justify-center " min-width="365" max-width="580">
              <div class="mb-15">
              <p class="pa-3 mx-auto" id="fhome4">{{ aboutSettingsdata.title2 }}</p>
              </div>

              <div>
              <p class="pa-3 ma-0 text-justify" id="fhome2">{{ aboutSettingsdata.desc2 }}</p>
              </div>
            </v-card>
          </v-col>

					<v-col class="" cols="11" sm="12" md="4" lg="5">
            <v-card flat class="d-flex align-center fill-height mx-auto" min-width="350" max-width="570">
              <v-img class="" width="100%" min-height="570" :src="aboutSettingsdata.img5"></v-img>
            </v-card>
          </v-col>
      </v-row>
    </div>

		<div class="my-15 d-flex justify-center"> 
			<v-card id="hcard4" :style="backgroundImageInlineStyle" class="d-flex fill-height align-center" min-width="400" min-height="600">
        
        <p style="color: rgba(255, 255, 255, 0.75); max-width:500px;"  id="fhome5">{{ aboutSettingsdata.desc3 }}
          <br>
          <br>

        Natalia Soetjipto</p>
        
			</v-card >
    </div>
		

    <div class="mt-5 mb-15">
      <v-card flat min-width="350" width="100%" height="auto" class="d-flex justify-center">
        <v-carousel
        height="550"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
        >
          <v-carousel-item
            v-for="testimony in listTestimony"
            :key="testimony.id"
            :src="testimony.img"
            max-width="100%"
            height="auto"
            style = "transition: 1s ease;"
          >
            <div class="d-flex align-center justify-center fill-height">
              <v-card id="hcard" class="pt-15 pb-12 px-15">
                <p class="pa-0 ma-0 text-center" id="fhome2">{{ testimony.desctext }}<br><br><br>
                <span style="font-size: 14px;">HAPPY BRIDE</span><br>
                <span class="text-no-wrap" id="fhome" style="font-size: 32px;">{{ testimony.name }}</span></p>
              </v-card>
            </div>
          </v-carousel-item>


        </v-carousel>
      </v-card>
    </div>

    <div class="mt-15">
      <v-card id="hcard3" min-width="350">
      <div class="pt-15 pb-12 text-center">
          <p style="font-size:18px;" id="fhome2">FEATURED ON</p>
      </div>

      <v-row class="mt-8 pb-15 mb-0 text-center justify-center">
        <div v-for="feature in listFeatureon" :key="feature.id">
        <v-col class="" cols="2">
            <img class="my-auto mx-auto" width="320" height="140" :src="feature.img">
        </v-col> 
        </div>  
        </v-row>
        
      </v-card>
    </div>

		<div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
import header from '../components/Header.vue'
import footer from '../components/Footer.vue'
import constant from '@/util/constant';
import axios from 'axios';

  export default {
    name: 'home-page',
    components : {
      'Header':header,
      'Footer':footer
    },

    data (){
    return{
      aboutSettingsdata:{},
      listFeatureon:[],
      listTestimony:[]
    }
  },

  computed: {
  backgroundImageInlineStyle () {
    return `background-image: linear-gradient(to left,rgba(116, 116, 116, 1) 61.99%, rgba(141, 141, 141, 0) 101.61%),url("${this.aboutSettingsdata.img6}");`;
  }
},

  methods : {
		category(type){
      this.$router.push('/category/' + type)
    },
    getFeatureon(){

        let a = this

        axios.get(constant.BASE_URL + "featureon")
        .then(function (response) {
          console.log(response)
          a.listFeatureon = response.data.data

        }).catch(function (error) {
          console.log(error);
        })

    },
    geTestimony(){

        let a = this

        axios.get(constant.BASE_URL + "testimony")
        .then(function (response) {
          console.log(response)
          a.listTestimony = response.data.data

        }).catch(function (error) {
          console.log(error);

        })

    },
    getAboutSettings(){

        let a = this

        axios.get(constant.BASE_URL + "settingsbout")
        .then(function (response) {
          console.log(response)
          a.aboutSettingsdata = response.data.data[0]

        }).catch(function (error) {
          console.log(error);

        })

    },

  },

  created(){
    this.getAboutSettings()
    this.getFeatureon()
    this.geTestimony()
  }

  }
</script>

<style scoped>
.body{
  padding:0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:man;
}

#hrow{
  justify-content:center;
}

#hcard{
  background: rgba(255,255,255,0.7);
}

#hcard2{
  min-height:570px;
}

#hcard3{
  background: #F5F5F5;
}

#hcard4{
  width:80%;
  margin-left: 5%;
  margin-right:5%;
  text-align: left;
  justify-content: end;
  padding-right: 60px;
}

#fhome{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 54px;
  color: #414141;
  
}

#fhomes{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  color: #414141;
}

#fhome2{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);

}

#fhome3{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);
}

#fhome4{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  color: #414141;

}

#fhome5{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);

}

#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imgLogo2{
  display: block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 450px;
  min-width: 335px;
  height: auto;
  width: 100%;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: black;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

@media only screen and (max-width:1264px){

}

@media only screen and (max-width:960px){
  #fhome{
    font-size: 40px;
    white-space:pre-line;
  }

  #fhomes{
    font-size: 30px;
  }

  #fhome2{
    font-size: 18px;
    white-space:pre-line;
  }

  #fhome4{
    font-size: 38px;
    white-space:pre-line;
  }

  #fhome5{
    font-size: 14px;

  }

  #hcard2{
    min-height:400px !important; 
  }

  #hcard4{
    background-image: linear-gradient(to left,rgba(116, 116, 116, 1) , rgba(141, 141, 141, 1));
    margin-left: 5px;
    margin-right: 5px;
    display: block;
    text-align: left;
    justify-content: center;
    padding: 5px;
  }
}

@media only screen and (max-width:600px){
  #fhome{
    font-size: 30px;
    white-space:pre-line;
  }

  #fhome2{
    font-size: 12px;
    white-space:pre-line;
  }

	#fhome4{
    font-size: 20px;
    white-space:pre-line;
  }

  #fhome5{
  font-size: 12px;
  }

  #hcard2{
    min-height:300px !important; 
  }

  #hcol{
    display:block;
  }

}

</style>