<template>
    <div class="body fill-height">
      <div>
        <Header></Header>
      </div>

    <div>
      <img class="imgLogo" width='250' height='150' src="../assets/logo.jpg">
    </div>

    <div class="hidden-sm-and-down">
      <v-card id="bg1" class="text-center py-5">
          <div class="my-3 bg3">
              <p id="p1" ><b>Waiting For Payment</b></p>
          </div>

          <div class="my-3 bg2">
              <p id="p1" class="bg2">Rp. 1.900.000</p>
          </div>

          <div class="my-3 bg3">
              <p id="p1">Your Order ID : 
              <span p>874294248</span></p>
          </div>
      </v-card>
    </div>

    <div class="hidden-md-and-up">
      <v-card min-width="300" id="bg1" class="text-center py-5">
          <div class="my-3 bg3">
              <p id="p1xs" ><b>Waiting For Payment</b></p>
          </div>

          <div class="my-3 bg2">
              <p id="p1xs" class="bg2">Rp. 1.900.000</p>
          </div>

          <div class="my-3 bg3">
              <p id="p1xs">Your Order ID : 
              <span p>874294248</span></p>
          </div>
      </v-card>
    </div>

    <div class="mt-10 ">
      <v-card min-width="300" id="bg1" class="text-center pt-5 pb-10">
        <div class="bg3 hidden-lg-and-down">
        <p id="p1">Please Complete The Payment Before <span>12:28 PM 26 Feb 2023</span></p>
        </div>

        <div class="d-none d-md-flex d-lg-flex d-xl-none justify-center flex-column bg3">
        <p id="p1">Please Complete The Payment Before </p><br>
        <p id="p1">12:28 PM 26 Feb 2023</p>
        </div>

        <div class="d-flex d-sm-flex d-md-none justify-center flex-column bg3">
        <p id="p1xs">Please Complete</p>
        <p id="p1xs">The Payment Before</p>
        <p id="p1xs">12:28 PM 26 Feb 2024</p>
        </div>

        <div class="hidden-sm-and-down">
          <v-card width="60vh" class="mx-auto">
              <v-row class="mx-auto mt-10">
                <v-col class="align-center justify-center my-auto">
                <img src="../assets/bca.png">
                </v-col>

                <v-row id="row" class=" align-center text-left">
                  <v-col>
                  <p>BCA</p>
                  <p>Jumlah</p>
                  </v-col>

                  <v-col>
                  <p><b>121345253662</b></p>
                  <p class="bg2">Rp. 1.900.000</p>
                  </v-col>
                </v-row>
              </v-row>
          </v-card>
        </div>

        <div class="hidden-md-and-up mx-2">
          <v-card  width="60vh" class="mx-auto">
            <div class="justify-center my-auto">
              <img src="../assets/bca.png">
            </div>

                <v-row id="row" class=" align-center text-left">
                  <v-col class="ms-5">
                  <p>BCA</p>
                  <p>Jumlah</p>
                  </v-col>

                  <v-col class="text-center">
                  <p><b>121345253662</b></p>
                  <p class="bg2">Rp. 1.900.000</p>
                  </v-col>
                </v-row>
              
          </v-card>
        </div>
      </v-card>
    </div>

    <div class="my-10 text-center hidden-xs-only">
      <v-btn @click="success" id="button">Confirm Payment</v-btn>
    </div>

    <v-card min-width="330" flat class="hidden-sm-and-up">
    <div class="my-10 text-center ">
      <v-btn @click="success" id="buttonxs">Confirm Payment</v-btn>
    </div>
    </v-card>

    </div>
</template>

<script>
import header from '../components/Header.vue'

export default {
  name: 'how-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      
    }
  },

  methods : {
    success(){
      this.$router.push('/success')
    },

  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
}

#button{
  background-color:  #0A9DBF;
  color:white;
  text-transform: capitalize;
  letter-spacing: normal;
  font-size: 25px;
  padding: 4vh 4vw;
  border-radius: 15px;
}

#buttonxs{
  background-color:  #0A9DBF;
  color:white;
  text-transform: capitalize;
  letter-spacing: normal;
  font-size: 20px;
  padding: 30px 30px;
  border-radius: 15px;
}

#p1{
  font-style:normal;
  font-weight:normal;
  font-size: 30px; 
}

#p1xs{
  font-style:normal;
  font-weight:normal;
  font-size: 20px; 
}

#p2{
  font-style:normal;
  font-weight:normal;
  font-size: 15px;
}

#p3{
  font-style:normal;
  font-weight:normal;
  font-size: 30px;
}

#row1{
  flex-direction: column;
}


#bg1{
  background-color: #2E2E2E;
  border-radius: 15px;
  margin-left: 5vw;
  margin-right: 5vw;
}

.bg2{
  color: #00AED6;
}

.bg3{
  color: white;
}


</style>