<template>
    <div class="body fill-height">

      <div>
        <Header></Header>
      </div>
        
        <v-col  style="padding: 40px;">

          <p class="texttitle">{{ category }} /  {{ type }} </p>

          <v-row >

            <v-col style="margin-top: 40px;"  sm="8" md="8" lg="8">
             <v-row dense>

              <v-col     
                v-for="card in listimage"
                sm="6" md="6" lg="6"
                :key="card">

                <v-img
                :src="card"       
                height="500">
                </v-img>
              
              </v-col>

             </v-row>
            </v-col>
        
            <v-col  
              sm="4" md="4" lg="4"
              style="text-align: left; 
              margin-top: 40px; 
              padding-left: 40px;">

              <p style="font-family:man;
                 font-size: 18px;
                 color: rgba(0, 0, 0, 0.5);"
              >{{ category }}</p>

              <p style="font-size: 30px;">{{ title }}</p>

              <p style="font-size: 24px;">{{ formatRupiah(price) }}</p>

              <p v-if="isBridal === false" style="font-size: 18px;">Available on</p>

              <v-btn v-if="isBridal" @click="contact" class="btn" >
              <p class="my-auto">BOOK AN APPOINTMENT</p>
              </v-btn>

              <div v-if="isBridal === false" class="d-flex flex-row">

                <img @click="goShopee" class="btnsosmed" src="../assets/shopee circle.png" />

                <img @click="goTokped"  class="btnsosmed" src="../assets/tokped circle.png" />

                <img @click="goLazada"  class="btnsosmed" src="../assets/lazada circle.png" />

                <img  @click="goZalora" class="btnsosmed" src="../assets/zalora circle.png" />
           
              </div>

              <p style="font-size: 18px; margin-top: 20px;">Style</p>

              <p style="font-size: 14px;">{{ style }}</p>

              <p style="font-size: 18px;">Product Details</p>

              <p style="font-size: 14px;">{{ productdetail }}</p>

            </v-col>
        
          </v-row>

        <v-col sm="12" md="12" lg="12">
          <v-tabs
              style="margin-top: 40px;"
              v-model="tab"
              align-with-title
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab
                v-for="item in items"
                :key="item"
              >
                {{ item }}
              </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" >
          <v-tab-item
            v-for="item in items"
            :key="item"
          >
            <v-card flat v-if="item == 'Detail'">
              <v-card-text v-text="description"></v-card-text>
            </v-card>

            <v-card flat v-if="item == 'Size & Fit'">
              <v-row >

                <v-col sm="12" md="5" lg="5">

                  <v-card-text style="max-width: 400px; ">Model Du Yue is 173cm tall, a UK6/8, wears size S. Model Mylina is 175cm tall, a UK6/8, wears size S.Kindly note that all measurements are subjected to a 1 inch allowance.</v-card-text>

                  <img  style="max-width: 600px;width: 100%; "  src="../assets/tables.png"/> 

                </v-col>
             
                <v-col sm="12" md="3" lg="3"  style="text-align: left;">
                  <img style="margin-left: 40px; max-width: 300px;" src="../assets/imgshirt.png"/> 

                </v-col>
                
              </v-row>

            </v-card>

            <v-card flat v-if="item == 'Material & Care'">
              <v-card-text>{{ material }}</v-card-text>
            </v-card>

          </v-tab-item>
        </v-tabs-items>
        </v-col>  
  

        <div style="text-align: center; margin-top: 40px;">
          <h1 style="font-family: man;">You might also like</h1>
        </div>

        <v-row style="padding: 40px;">

          <v-col style="cursor: pointer;" class="d-flex flex-column" sm="3" md="3" lg="3" v-for="item in listRekomen" :key="item.id">
    
            <img @click="goSugestionPage(item.id)" style="height: 400px;max-width: 300px;"   :src="item.listphotos" />

            <p @click="goSugestionPage(item.id)" style="font-size: 20px; margin-top: 10px;">{{ item.name }}</p>

            <p @click="goSugestionPage(item.id)" style="margin-top: -20px;">{{ formatRupiah(item.price) }}</p>

          </v-col>

        </v-row>
      </v-col>

        <div>
          <Footer></Footer>
        </div>
        
    </div>
</template>

<script>
import header from '../components/Header.vue'
import footer from '../components/Footer.vue';
import axios from 'axios';
import constant from '../util/constant';
import Swal from 'sweetalert2'

export default {
  name: 'detail-page',
  components:{
    'Header':header,
    'Footer':footer
  },

  data (){
    return{
      docid:0,
      selection1:"",
      listimage:[],
      title:"",
      description:"",
      price:"",
      totalprice:0,
      productdetail:"",
      style:"",
      material:"",
      linkshopee:"",
      linktokped:"",
      linkzalora:"",
      linklazada:"",
      productid:1,
      listRekomen:[],
      category:"",
      isBridal:false,
      type:"",
      tab: null,
        items: [
          'Detail', 'Size & Fit', 'Material & Care',
        ],
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing el'
    }
  },

  methods : {
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          });

          return formatter.format(number);
},
goShopee(){
  window.open(this.linkshopee, '_blank').focus();
},
goTokped(){
  window.open(this.linktokped, '_blank').focus();
},
goLazada(){
  window.open(this.linklazada, '_blank').focus();
},
goZalora(){
  window.open(this.linkzalora, '_blank').focus();
},
goSugestionPage(id){
  this.$router.push('/detail/' + id)
  this.getData()
  window.scrollTo(0,0);
},
getSugestion(){

  let a = this

  axios.get(constant.BASE_URL + "product" )
      .then(function (response) {

        a.listRekomen = a.randomList(response.data.data)

        for (let i = 0; i < a.listRekomen.length; i++){
          let listimg = a.listRekomen[i].listphotos.split(",")

          a.listRekomen[i].listphotos = listimg[0]
        }

      }).catch(function (error) {
        
        console.log(error);
      })

},
contact(){
      window.open("https://wa.me/6285320007699", '_blank').focus();
    },
randomList(listData) {
  const shuffledArray = listData.sort(() => 0.5 - Math.random()); // Shuffle the array randomly
  const randomSelection = shuffledArray.slice(0, 4); // Get the first 4 elements from the shuffled array
  return randomSelection;
},
getData(){

      this.docid = this.$route.params.id

      let a = this
  
      axios.get(constant.BASE_URL + "product/" + a.docid)
      .then(function (response) {
     
        var item = response.data.data[0]

        console.log(item)

        let listimg = item.listphotos.split(',')

        a.listimage = listimg

        a.price =  item.price
        a.title = item.name
        a.description = item.description  
        a.totalprice = item.price
        a.productid = item.id
        a.productdetail = item.productdetail
        a.style = item.style
        a.linkshopee = item.linkshopee
        a.linktokped = item.linktokped
        a.linkzalora = item.linkzalora
        a.linklazada = item.linklazada
        a.category = item.category
        a.type =  item.type
        if(item.type == "bridal"){
          a.isBridal = true
        }

      })
      .catch(function (error) {
        
        console.log(error);
      })
    },
    addCart(){

var userid = localStorage.getItem("userid");
    if(userid == null){
      this.$router.push('/login')
    }else{


axios.post( constant.BASE_URL + 'cart', 
  {
    userid:userid,
    productid:this.productid,
    qty:1,
    size:this.selection1,
    totalprice :this.totalprice
  })
.then(function (response) {

  console.log(response);

  Swal.fire({
    icon: 'success',
    title: "<h3 style='font-family:ros;'>Berhasil ditambahkan</h3>",
    showConfirmButton: false,
  })
         
    }).catch(function (error) {
      
      Swal.fire({
        icon: 'error',
        title: error
      })
      console.log(error);
    });     
    }
   },
},
watch :{
  
 
},
  created(){
    this.getSugestion()
    this.getData()
  }
}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

.btnsosmed{
  width: 40px;
  height: 40px;
  margin: 4px;
  cursor: pointer;
}

.btn{
  border-radius: 30px;
  padding: 8px 24px;
  font-size: 14px;
  letter-spacing: 0.08em;
  font-weight: bold;
}

.md{
  margin-left: 20vw ;
  margin-right: 20vw;
}

.xs{
  margin-left: 10vw ;
  margin-right: 10vw;
}

#flex{
  margin-top: 40px;
  
}

#flex1{
  flex-grow: 1;
}

#margin{
  margin-top: 20px;
}

#button{
  padding:25px 20px;
  border-radius: 15px;
  font-size: 25px;
  color: white;
  background-color: rgba(0, 0, 0, 0.82);
  text-transform: capitalize;
}

#button-md{
  padding:25px 20px;
  border-radius: 15px;
  font-size: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.82);
  text-transform: capitalize;
}

#button-md{
  padding:25px 20px;
  border-radius: 15px;
  font-size: 20px;
  color: white;
  background-color: rgba(0, 0, 0, 0.82);
  text-transform: capitalize;
}

.texttitle {
  margin-top: 40px;
  font-family:man;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
}

#h{
  font-size: 40px;
}

#h-md{
  font-size: 30px;
}

#h-xs{
  font-size: 25px;
}

#p{
  font-size: 25px;
}

#p-md{
  font-size: 20px;
}

#p-xs{
  font-size: 15px;
}

#p2{
  font-size: 30px;
  /* font-family: inter; */
  font-weight: 700;
  color: #0F4C85;
}

#p2-md{
  font-size: 20px;
  /* font-family: inter; */
  font-weight: 700;
  color: #0F4C85;
}

#p2-xs{
  font-size: 15px;
  /* font-family: inter; */
  font-weight: 700;
  color: #0F4C85;
}

#font{
  font-size:20px;
  /* font-family: inter; */
}

#font-xs{
  font-size:15px;
  /* font-family: inter; */
}

@media only screen and (max-width: 600px) {
.imglogo{
  max-width: 250px !important;
 }
}
</style>