<template>
    <div class="body fill-height">
      <div>
      <Header></Header>
      </div>
  
        <div>
  
        <v-card class="d-flex px-6 search" outlined width="20vw"> 
          <div>
            <v-text-field 
            style=""
            label="Search Product" 
            flat
            solo
            hide-details
            prepend-icon="mdi-magnify"
            single-line>
            </v-text-field>
          </div> 
        </v-card>
           
        <div>
          <v-row class="cardheader">
        
          
            <v-col class="col2 justify-center headercontent">
              <p class="my-auto" style="color: white;">Item</p>
            </v-col>
  
            <v-col class="col3 justify-center  headercontent">
              <p class="my-auto" style="color: white;">Status</p>
            </v-col>
  
            <v-col class="col4 justify-center  headercontent">
              <p class="my-auto" style="color: white;">Order ID</p>
            </v-col>
            
            <v-col class="col5 justify-center  headercontent">
              <p class="my-auto" style="color: white;">Shipping Address</p>
            </v-col>
  
            <v-col class="col6 justify-center  headercontent">
              <p class="my-auto" style="color: white;">Action</p>
            </v-col>
          </v-row>
        </div>
            
        <v-data-table
        outlined
        v-model="selected"
        :headers="headers"
        :items="desserts"
        item-key="name"
        class="cardheader2"
        :items-per-page="10"
        hide-default-footer
        hide-default-header>
  
  
        <template v-slot:[`item.item`]="{ item }">
          <v-col class="col2 justify-start">
            <img src="../assets/model6.jpg" width="100" height="100" style="border-radius: 8px;">
            <p class="my-auto ms-5" style="font-size: 15px; font-weight: bold;" >{{ item.item }}</p>
          </v-col>
        </template>
  
        <template v-slot:[`item.status`]="{ item }">
          <v-col class="col3 justify-center"> 
            <p class="my-auto" style="color:grey;">{{ item.status }}</p>
        
          </v-col>
        </template>
  
        <template v-slot:[`item.quantity`]="{  }">
  
            <v-col class="col4 justify-center">
              <button class="px-2 my-auto mx-auto" style="border-radius: 20px; border: grey 1px solid;">-</button>
              <button class="px-2 my-auto mx-auto" style="border-radius: 20px; border: grey 1px solid;">1</button>         
              <button class="px-2 my-auto mx-auto" style="border-radius: 20px; border: grey 1px solid;">+</button>
            </v-col>
  
        </template>
  
  
        <template v-slot:[`item.price`]="{ item  }">
          <v-col class="col5 justify-center">
            <p style="color: #0F4C85; font-size: 15px;">Rp. {{ item.price }}</p>
          </v-col>
        </template>
  
        <template v-slot:[`item.action`]="{  }">
          <v-col class="col6 justify-center">
          <v-btn style="background-color: black;
                    color: white;
                    border-radius: 20px;
                    font-size: 10px;
                    height: 18px;
                    text-transform: none;">
                    Delivery Status</v-btn>
        </v-col>
        </template>
      </v-data-table>
  
  
      <v-row class="d-flex justify-center flex-row">
  
   
        <div class="text-center pa-2">
            <v-pagination
              
              class="pagination"
              v-model="page"
              :length="pageCount"
            ></v-pagination>
  
          </div>
  
        </v-row>
  
    </div>
  
      <div>
      <Footer></Footer>
      </div>
  
    </div>
  </template>
  
  <script>
  
  import footer from '../components/Footer.vue';
  import header from '../components/Header.vue';
  
  
  export default {
    name: 'myorder-page',
    components : {
        'Header':header,
        'Footer':footer
      },
  
      data(){
        return{
          page: 1,
          ischeckall:false,
          pageCount: 1,
          itemsPerPage: 10,
          singleSelect: false,
          selected: [],
          headers: [
            {
              text: 'Item',
              value: 'item',
              class: 'grey darken-1',
              align:"center",
            },
            { text: 'Status', value: 'status',  class: 'grey darken-1', align:"center",
              },
            { text: 'Order id', value: 'orderid',     class: 'grey darken-1', align:"center"  },
            { text: 'Address', value: 'address',     class: 'grey darken-1' ,width:200},
            { text: 'Action', value: 'action',     class: 'grey darken-1',  },
          ],
          desserts: [
            {
              item: 'Seat Dress',
              status: "On Delivery",
              orderid: 874429428,
              address: "jl abcde no 57 jawa barat 4057 indonesia"
            },
            {
              item: 'Seat Dress',
              status: "On Delivery",
              orderid: 874429428,
              address: "jl abcde no 57 jawa barat 4057 indonesia"
            },
            {
              item: 'Seat Dress',
              status: "On Delivery",
              orderid: 874429428,
              address: "jl abcde no 57 jawa barat 4057 indonesia"
            },
         
          ],
        }
      },
  
      methods: {
  
        checkout(){
        this.$router.push('/checkout')
        },
  
      }
  }
  
  </script>
  
  <style scoped>
  .body{
    padding:0px;
    margin: 0px;
    position: relative;
    height: 100%;
    width:100%;
    font-family:ros;
  }

  .pagination{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .col2{
    display: flex;
    flex-basis: 27%;
  }
  
  .col3{
    display: flex;
    flex-basis: 11%;
  }
  
  .col4{
    display: flex;
    flex-basis: 13%;
  }
  
  .col5{
    display: flex;
    flex-basis: 30%;
  }
  
  .col6{
    display: flex;
    flex-basis: 18%;
  }
  
  .search{
    margin:5px 15px;
    padding:5px;
    border-radius:20px;
    border: 2px solid;
    margin-top: 40px;
  }
  
  .cardheader{
    background-color: rgba(46, 46, 46, 0.8); 
    padding:10px 0px;
    border-radius: 15px;
    margin:5px 15px; 
    margin-top: 40px;
  }
  
  .cardheader2{
    padding:10px 0px;
    border-radius: 15px;
    margin:5px 15px; 
    margin-top: 40px;
    border: 2px solid;
  }
  
  .content {
    padding: 50px;
  }
  
  .table{
    margin-top: 40px;
    border-radius: 15px;
  }
  
  .some-other-style {
    background: blue;
  }
  .my-header-style {
    color: #6f8fb9 !important;
  }
  
  .textselecall{
    display: none;
  }
  
  
  @media only screen and (max-width: 600px) {
    .search{
      width: 300px !important;
    }
    .texttotal{
      margin-left: 50px;
    }
  
    .headercontent{
      display: none;
    }
  
    .textselecall{
    display: block;
  }
  
    .pagination{
      text-align: center;
      margin-right: 120px;
    }
  
  }
  
  
  </style>