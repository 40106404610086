<template>
    <div class="body fill-height">

      <div>
        <Header></Header>
      </div>

      <v-row class="justify-center" v-if="view1"> 
        <v-col class="login " cols="10" sm="10" md="6" lg="6" >
          <v-card min-width="300" flat class="">
            <v-row>
              <v-col cols="12">
                <v-card-title class="justify-center py-3"><h1 id="title">CREATE <br class="hidden-sm-and-up">YOUR ACCOUNT</h1></v-card-title>
              </v-col>
            </v-row>

            <v-form 
            ref="form"
            lazy-validation>
            <v-container class="d-flex justify-center mt-15">
            <v-row class="justify-center">
            <v-col cols="10" sm="10" md="10" lg="8" class="my-auto pa-0">
            <v-text-field
              class="my-auto"
              underlined
              v-model="name"
              label="Name"
              required
            ></v-text-field>
            </v-col>
            </v-row>
            </v-container>

            <v-container class="d-flex justify-center mt-5">
            <v-row class="justify-center">
            <v-col cols="10" sm="10" md="10" lg="8" class="my-auto pa-0">
            <v-text-field
              class="my-auto"
              underlined
              v-model="email"
              label="Email"
              required
            ></v-text-field>
            </v-col>
            </v-row>
            </v-container>

            <v-container class="d-flex justify-center mt-5">
            <v-row class="justify-center">
            <v-col  cols="10" sm="10" md="10" lg="8" class="my-auto pa-0">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                underlined
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"

                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            </v-row>
            </v-container>

            <v-container class="d-flex justify-center">
            <v-row class="justify-center">
            <v-col cols="12" sm="10" md="10" lg="8" class="mt-6 pa-0 mx-auto">
            <v-btn
            id="fbx" 
            block
            color="#404040"
            class="py-3"
            height="45"
            @click="create">
              CREATE ACCOUNT
            </v-btn>
            </v-col>
            </v-row>
            </v-container>

            <div class="mt-5">
            <v-row class="justify-center">
              <v-col cols="10" sm="10" md="10" lg="8">
                <p class="font text-center my-auto">Or</p>
              </v-col>
            </v-row>
            </div>

            <!-- <v-container class="d-flex justify-center">
            <v-row class="justify-center">
            <v-col cols="" sm="" md="" lg=""></v-col>
            
            <v-col cols="10" sm="5" md="5" lg="4" class="mt-4 pa-0 mx-0">
            <div>
            <v-btn
            id="fbx" 
            block
            color="#3B5998"
            class="py-3"
            @click="login">
              Continue with Facebook
            </v-btn>
            </div>
            </v-col>
                        
            <v-col cols="10" sm="5" md="5" lg="4" class="mt-4 pa-0 mx-0">
            <v-btn
            id="fbx" 
            block
            color="#DD4B39"
            class="py-3"
            @click="login">
              Continue with Google
            </v-btn>
            </v-col>
            

            <v-col cols="" sm="" md="" lg=""></v-col>
            </v-row>
            </v-container> -->
<!-- 
            <v-container class="d-flex justify-center">
            <v-row class="justify-center">
            <v-col cols="12" sm="10" md="10" lg="8" class="mt-4 pa-0 mx-auto">
            <v-btn
            id="fbxface" 
            block
            color="#3B5998"
            class="py-3"
            height="45"
            @click="login">
              Continue with Facebook
            </v-btn>
            </v-col>
            </v-row>
            </v-container>

            <v-container class="d-flex justify-center">
            <v-row class="justify-center">
            <v-col cols="12" sm="10" md="10" lg="8" class="mt-4 pa-0 mx-auto">
            <v-btn
            id="fbxgo" 
            block
            color="#DD4B39"
            class="py-3"
            height="45"
            @click="login">
              Continue with Google
            </v-btn>
            </v-col>
            </v-row>
            </v-container> -->

            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center" v-if="view2">
        <v-col class="login " cols="10" sm="10" md="7" lg="6" >
          <v-card min-width="300" flat class="">
            <v-row class="flex-column">
              <v-col cols="12">
                <v-card-title class="justify-center text-no-wrap"><h1 id="title">CREATE YOUR MAIL</h1></v-card-title>
              </v-col>

              <v-col class="d-flex justify-center pt-0" cols="12">
                <img width="138" height="138" src="../assets/box.png">
              </v-col>

              <v-col>
                <v-card flat class="text-center fr">
                  <p class="mb-0">Email sent to <span><b>indira_g@gmail.com</b></span></p>

                  <p class="mb-0">Please check your inbox <br class="hidden-sm-and-up"> or spam folder. 
                  <br> Click the link on our email <br class="hidden-sm-and-up"> to active your account
                  </p><br>

                  <p class="mb-0">Didn't get the email?</p>
                </v-card>
              </v-col>

              <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
                <v-btn 
                id="fbx" 
                block
                color="#404040"
                class="py-3"
                height="45"
                @click="resend">
                  RESEND
                </v-btn>
                <p class="mb-0 fr text-center mt-5 text-no-wrap">or <b><u>Change email address</u></b></p>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center" v-if="view3">
        <v-col class="login " cols="10" sm="10" md="7" lg="6" >
          <v-card min-width="300" flat class="">
            <v-row class="flex-column">
              <v-col cols="12">
                <v-card-title class="justify-center text-no-wrap"><h1 id="title">WELCOME! </h1></v-card-title>
              </v-col>

              <v-col class="d-flex justify-center pt-0" cols="12">
                <img width="138" height="138" src="../assets/success.png">
              </v-col>

              <v-col>
                <v-card flat class="text-center fr">
                  <p class="mb-0">You have successfully <br class="hidden-sm-and-up"> created your account.</p>

                  <p class="mb-0">Login to continue <br class="hidden-sm-and-up"> your special journey</p>
                </v-card>
              </v-col>

              <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
                <v-btn 
                id="fbx" 
                block
                color="#404040"
                class="py-3"
                height="45"
                @click="success">
                  LOGIN
                </v-btn>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import header from '../components/Header.vue'
import axios from 'axios';
import Swal from 'sweetalert2';
import constant from '../util/constant';

export default {
  name: 'register-page',
  components:{
    'Header':header,
  },

  data(){
    return{
      email:"",
      password:"",
      password2:"",
      show1:false,
      show2:false,
      view1: true,
      view2: false,
      view3: false,
    }
  },

  methods:{
    create(){
      this.view1 = false
      this.view2 = true
      this.view3 = false

    },

    resend(){
      this.view1 = false
      this.view2 = false
      this.view3 = true

    },

    success(){
      this.view1 = true
      this.view2 = false
      this.view3 = false

      this.$router.push('/login')
    },

    login(){

    },
    register(){
      let a = this

      if (a.password === a.password2 && a.password != "" && a.password2 !=""){
      
      axios.post( constant.BASE_URL + 'register', {
          email: a.email,
          password: a.password,
          name: "",
          role: "user"
        })
        .then(function (response) {

          if(response.data.code == 301){
            Swal.fire({
            icon: 'error',
            title: "<h5 style='font-family:ros;'>Email Sudah Terdaftar</h5>",
            text:  response.data.status
          })
          }else{
            Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Register Berhasil</h3>",
            
          })
          a.$router.push('/login');
          }
          
          console.log(response);
          
        })
        .catch(function (error) {
           
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Error</h3>",
          })
          console.log(error);
        });
        }else if (a.password == "" || a.password2 == "" || a.password == "" && a.password2 == ""){
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Password Kosong</h3>",
        })
        }else if (a.password != a.password2){
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Password Tidak Sama</h3>",
        })
        }else {
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Error Error</h3>",
        })
      }
    },

  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding: 0;
  font-family: man;
}

.fr{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.75);
}

#title{
  text-align: center;
  letter-spacing: 0.04em;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 45px;
  color: rgba(0, 0, 0, 0.75);
}

#fb{
  letter-spacing: 2px;
  text-transform: uppercase;
}

#fbxs{
  letter-spacing: 1px;
  text-transform: uppercase;
}

#fbx{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbxface{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbxgo{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbx1{
  border-radius: 50px;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: capitalize;
  
}

#fbx2{
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.fp{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.font{
  font-size: 20px;
}
.card{
  margin-right: 50px;
}

.bg{
  padding: 5px 20px;
}

.login{
  margin-top: 30px;
}

p a{
  text-decoration: none;
  color:black;
}

#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: black;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}


</style>