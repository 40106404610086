<template>
  <div class="body fill-height">

    <div>
      <Header></Header>
    </div>

    <v-row class="justify-center" v-if="view1">
      <v-col class="login " cols="10" sm="10" md="6" lg="6" >
        <v-card min-width="300" flat class="">
          <v-row>
            <v-col cols="12">
              <v-card-title class="justify-center"><h1 id="title">LOGIN</h1></v-card-title>
            </v-col>
          </v-row>

          <v-form 
          ref="form"
          lazy-validation>
          <v-container class="d-flex justify-center mt-15">
          <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="8" class="my-auto pa-0">
          <v-text-field
            class="my-auto"
            underlined
            v-model="email"
            label="Email"
            required
          ></v-text-field>
          </v-col>
          </v-row>
          </v-container>

          <v-container class="d-flex justify-center mt-5">
          <v-row class="justify-center">
          <v-col  cols="10" sm="10" md="10" lg="8" class="my-auto pa-0">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              underlined
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"

              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          </v-row>
          </v-container>

          <v-container class="d-flex justify-center">
          <v-row class="justify-center">
          <!-- <v-col cols="2"></v-col>
          
          <v-col class="ps-0 text-left my-auto hidden-sm-and-up" cols="10">
            <v-checkbox
            v-model="selected"
            label="Remembered Me">
            </v-checkbox> 
            
            <v-btn class="px-0" id="fbxs" plain>
              Forgot Password
            </v-btn>
          </v-col>

          <v-col class="ps-0 text-left my-auto hidden-xs-only" cols="5">
            <v-checkbox
            v-model="selected"
            label="Remembered Me">
            </v-checkbox> 
          </v-col> -->

          <!-- <v-col class="my-auto text-right pe-0" cols="10" sm="10" md="10" lg="8"> 
            <v-btn @click="forget" id="" text class="fp px-0">
              Forgot Password?
            </v-btn>
          </v-col> -->
          </v-row>
          </v-container>

          <v-container class="d-flex justify-center">
          <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="8" class="mt-6 pa-0 mx-auto">
          <v-btn
          id="fbx" 
          block
          color="#404040"
          class="py-3"
          height="45"
          @click="login">
            LOGIN
          </v-btn>
          </v-col>
          </v-row>
          </v-container>

          <v-container class="justify-center d-none">
          <v-row>
          <v-col cols="2"></v-col>
          <!-- <v-col cols="10" class="mt-6 pa-0 ms-auto">
          <v-btn
          id="fbx1"
          block
          color=#FFFFFF
          class="py-7 hidden-xs-only"
          @click="login">
          <img width="58" height="51" src="../assets/google.png">
            Login with Google
          </v-btn>

          <v-btn
          id="fbx1"
          block
          color=#FFFFFF
          class="py-7 hidden-sm-and-up"
          @click="login">
          <img width="58" height="51" src="../assets/google.png">
            Login
          </v-btn>
          </v-col> -->
          </v-row>
          </v-container>

          <div class="mt-5">
          <v-row class="justify-center">
            <v-col cols="10" sm="10" md="10" lg="8" class="hidden-xs-only">
            <p class="font text-center my-auto">Don't have an account? <a @click="register" href="#"><b>Create Account</b></a></p>
            </v-col>

            <v-col cols="12" class="hidden-sm-and-up">
            <p class="font text-center"><a @click="register" href="#"><b>Create Account</b></a></p>
            </v-col>
          </v-row>
          </div>

          <!-- <v-container class="d-flex justify-center">
          <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="8" class="mt-4 pa-0 mx-auto">
          <v-btn
          id="fbxface" 
          block
          color="#3B5998"
          class="py-3"
          height="45"
          @click="login">
            Sign in with Facebook
          </v-btn>
          </v-col>
          </v-row>
          </v-container>

          <v-container class="d-flex justify-center">
          <v-row class="justify-center">
          <v-col cols="10" sm="10" md="10" lg="8" class="mt-4 pa-0 mx-auto">
          <v-btn
          id="fbxgo" 
          block
          color="#DD4B39"
          class="py-3"
          height="45"
          @click="login">
            Sign in with Google
          </v-btn>
          </v-col>
          </v-row>
          </v-container> -->

          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center" v-if="view2">
      <v-col class="login " cols="10" sm="10" md="6" lg="6" >
        <v-card min-width="300" flat class="">
          <v-row class="flex-column">
            <v-col cols="12">
              <v-card-title class="justify-center"><h1 id="title">FORGOT <br class="hidden-sm-and-up"> PASSWORD?</h1></v-card-title>
            </v-col>

            <v-col>
              <v-card flat class="text-center fr">
                <p class="mb-0">Enter registered <br class="hidden-sm-and-up"> 
                email address and <br class="hidden-xl-and-up"> 
                we will send you a link <br class="hidden-sm-and-up"> 
                to reset your password</p>
              </v-card>
            </v-col>

            <v-col cols="10" sm="10" md="10" lg="8" class="my-auto pt-7 mx-auto">
              <v-text-field
              class="my-auto"
              underlined
              v-model="email"
              label="Email"
              required
              >
              </v-text-field>
            </v-col>

            <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
              <v-btn 
              id="fbx" 
              block
              color="#404040"
              class="py-3"
              height="45"
              @click="forgetmail">
                LOGIN
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center" v-if="view3">
      <v-col class="login " cols="10" sm="10" md="7" lg="6" >
        <v-card min-width="300" flat class="">
          <v-row class="flex-column">
            <v-col cols="12">
              <v-card-title class="justify-center text-no-wrap"><h1 id="title">CREATE YOUR MAIL</h1></v-card-title>
            </v-col>

            <v-col class="d-flex justify-center pt-0" cols="12">
              <img width="138" height="138" src="../assets/box.png">
            </v-col>

            <v-col>
              <v-card flat class="text-center fr">
                <p class="mb-0">Email sent to <span><b>indira_g@gmail.com</b></span></p>

                <p class="mb-0">Please check your inbox <br class="hidden-sm-and-up"> or spam folder. 
                <br> Click the link on our email <br class="hidden-sm-and-up"> to reset your password.
                </p><br>

                <p class="mb-0">Didn't get the email?</p>
              </v-card>
            </v-col>

            <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
              <v-btn 
              id="fbx" 
              block
              color="#404040"
              class="py-3"
              height="45"
              @click="resend">
                RESEND
              </v-btn>
              <p class="mb-0 fr text-center mt-5 text-no-wrap">or <b><u>Change email address</u></b></p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center" v-if="view4">
      <v-col class="login " cols="10" sm="10" md="6" lg="6" >
        <v-card min-width="300" flat class="">
          <v-row class="flex-column">
            <v-col cols="12">
              <v-card-title class="justify-center"><h1 id="title">CREATE NEW <br class="hidden-sm-and-up"> PASSWORD</h1></v-card-title>
            </v-col>

            <v-col cols="10" sm="10" md="10" lg="8" class="my-auto pt-7 mx-auto">
              <v-text-field
              class="my-auto"
              underlined
              v-model="npass"
              label="New Password"
              required
              >
              </v-text-field>
            </v-col>

            <v-col cols="10" sm="10" md="10" lg="8" class="my-auto mx-auto">
              <v-text-field
              class="my-auto"
              underlined
              v-model="cpass"
              label="Confirm Password"
              required
              >
              </v-text-field>
            </v-col>

            <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
              <v-btn 
              id="fbx" 
              block
              color="#404040"
              class="py-3"
              height="45"
              @click="create">
                CREATE PASSWORD
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center" v-if="view5">
      <v-col class="login " cols="10" sm="10" md="7" lg="6" >
        <v-card min-width="300" flat class="">
          <v-row class="flex-column">
            <v-col cols="12">
              <v-card-title class="justify-center text-no-wrap"><h1 id="title">PASSWORD <br class="hidden-sm-and-up"> UPDATED </h1></v-card-title>
            </v-col>

            <v-col class="d-flex justify-center pt-0" cols="12">
              <img width="138" height="138" src="../assets/success.png">
            </v-col>

            <v-col>
              <v-card flat class="text-center fr">
                <p class="mb-0">You have successfully <br class="hidden-sm-and-up"> changed your password</p>
              </v-card>
            </v-col>

            <v-col cols="10" sm="10" md="11" lg="8" class="mx-auto">
              <v-btn 
              id="fbx" 
              block
              color="#404040"
              class="py-3"
              height="45"
              @click="success">
                LOGIN
              </v-btn>
            </v-col>

          </v-row>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import header from '../components/Header.vue'
import axios from 'axios';
import Swal from 'sweetalert2'
import constant from '../util/constant';

export default {
  name: 'login-page',
  components:{
    'Header':header,
  },
  data (){
    return{
      email:"",
      password:"",
      show1: false,
      view1 : true,
      view2 : false,
      view3 : false,
      view4 : false,
      view5 : false,

    }
  },

  methods : {
    register(){
      this.$router.push('/register')
    },

    forget(){
      this.view1 = false
      this.view2 = true
      this.view3 = false
      this.view4 = false
      this.view5 = false
    },

    forgetmail(){
      this.view1 = false
      this.view2 = false
      this.view3 = true
      this.view4 = false
      this.view5 = false
    },

    resend(){
      this.view1 = false
      this.view2 = false
      this.view3 = false
      this.view4 = true
      this.view5 = false
    },

    create(){
      this.view1 = false
      this.view2 = false
      this.view3 = false
      this.view4 = false
      this.view5 = true
    },

    success(){
      this.view1 = true
      this.view2 = false
      this.view3 = false
      this.view4 = false
      this.view5 = false
    },

    login(){

      let a = this

      axios.post( constant.BASE_URL + 'login', 
      {
          email: a.email,
          password: a.password
        })
        .then(function (response) {

          console.log(response);

          var item = response.data.data[0];

          localStorage.setItem("userid", item.id);
          localStorage.setItem("email", item.email);
          localStorage.setItem("role", item.role);

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Login Berhasil</h3>",
            showConfirmButton: false,
          })

          if(item.role == "admin"){
            a.$router.push('/admin');
          }else{
            a.$router.push('/');
          }
                 
        
        }).catch(function (error) {
           
          Swal.fire({
            icon: 'error',
            title: "<h5 style='font-family:ros;'>Email atau Password Salah</h5>"
          })
          console.log(error);
        });

    }

  },

  created(){

  }

}

</script>

<style scoped>
.body{
  padding: 0;
  font-family: man;
}

.fr{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.75);
}

#title{
  text-align: center;
  letter-spacing: 0.04em;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 45px;
}

#fb{
  letter-spacing: 2px;
  text-transform: uppercase;
}

#fbxs{
  letter-spacing: 1px;
  text-transform: uppercase;
}

#fbx{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbxface{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbxgo{
  border-radius: 30px;
  letter-spacing:0.08em;
  text-transform:none;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}

#fbx1{
  border-radius: 50px;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: capitalize;
  
}

#fbx2{
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.fp{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.font{
  font-size: 20px;
}
.card{
  margin-right: 50px;
}

.bg{
  padding: 5px 20px;
}

.login{
  margin-top: 30px;
}

p a{
  text-decoration: none;
  color:black;
}

#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: black;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}



</style>