<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <!-- <div class="hidden-md-and-up">
      <img class="imgLogo2" width='450' height='100' src="../assets/logo2.png">
    </div>

    <div class="hidden-sm-and-down">
      <img class="imgLogo" width='450' height='100' src="../assets/logo2.png">
    </div> -->

    <div class="hidden-sm-and-down">
      <v-toolbar flat class="d-flex justify-center my-3">
        <v-toolbar-items flat class="hidden-md-and-down">
        <ul class="navBar2">
          <li class="li-lg"><v-btn id="fs-lg" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('culture')" text><a href='#'>Culture</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
        </ul>
        </v-toolbar-items>

        <v-toolbar-items flat class="d-none d-md-flex d-lg-none">
        <ul class="navBar2">
          <li class="li-md"><v-btn id="fs-md" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('culture')" text><a href='#'>Ready To Wear</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
        </ul>
        </v-toolbar-items>

      </v-toolbar>
    </div>

    <div>

    </div>

    <div class="mt-5">
    <v-card flat min-width="315" width="100%" height="auto" class="d-flex justify-center">
    <v-carousel
    height="810"
    cycle
    interval="5000"
    hide-delimiter-background
    show-arrows-on-hover
    >
      <v-carousel-item
        v-for="carousel in listCarousel"
        cover
        :key="carousel.id"
        :src="carousel.img"
        max-width="100%"
        height="auto"
        style = "transition: 1s ease;"
      >
        <div class="d-flex align-center justify-center fill-height">
          <v-card flat id="hcard4" class="">
            <p style="color: white;" class="pa-0 ma-0 text-center" id="fhome2">{{ carousel.title }}</p>
            <p id="fhome4" class="text-center">{{ carousel.description }}</p>
            <div class="text-center">
              <v-btn  @click="category('all')" id="border2" outlined> {{ carousel.descbtn }} </v-btn>
            </div>
          </v-card>
        </div>
      </v-carousel-item>

 
    </v-carousel>
    </v-card>
    </div>

    <div class="my-15">
      <v-row class="d-flex flex-sm-column flex-lg-row">
        <v-col  id="hcol" class="">
          <v-card flat min-width="300" class="mx-4">
            <p class="pa-3 ma-0  text-center" id="fhome">{{ homeSettingsdata.titlehome }}</p>
          </v-card>
        </v-col>

        <v-col>
          <v-card flat min-width="300" class="d-flex justify-center mx-5">
            <p class="pa-3 text-justify"  id="fhome2">{{ homeSettingsdata.deschome }}</p>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div id="margin">
      <v-row class="mx-5 justify-center">
        <v-col cols="12" md="6" lg="3">
        <v-card flat class="mx-auto" max-width="600" min-width="300"> 
          <v-img contain width="100%" max-height="600" min-height="350" :src="homeSettingsdata.imgbride">
            <div class="hidden-xs-only">
            <v-card-title id="ct" class="justify-center my-auto">Classic Bride</v-card-title>
            <v-card-actions id="ct1" class="justify-center"><v-btn id="border1" @click="category('bridal')" outlined>  SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>

            <div class="hidden-sm-and-up">
            <v-card-title id="ctxs" class="justify-center">Classic Bride</v-card-title>
            <v-card-actions id="ct1xs" class="justify-center"><v-btn id="border1xs" @click="category('bridal')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>
            <v-card-text></v-card-text>
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="3">
        <v-card flat class="mx-auto" max-width="600" min-width="300">
          <v-img contain width="100%" max-height="600" min-height="350" :src="homeSettingsdata.imgparty">
            <div class="hidden-xs-only">
            <v-card-title id="ct" class="justify-center">Modern Party</v-card-title>
            <v-card-actions id="ct1" class="justify-center"><v-btn id="border1" @click="category('party')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>

            <div class="hidden-sm-and-up">
            <v-card-title id="ctxs" class="justify-center">Modern Party</v-card-title>
            <v-card-actions id="ct1xs" class="justify-center"><v-btn id="border1xs" @click="category('party')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>
          </v-img>
          
        </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="3">
        <v-card flat class="mx-auto" max-width="600" min-width="300">
          <v-img contain width="100%" max-height="600" min-height="350" :src="homeSettingsdata.imgbatik">
            <div class="hidden-xs-only">
            <v-card-title id="ct" class="justify-center">Batik</v-card-title>
            <v-card-actions id="ct1" class="justify-center"><v-btn id="border1" @click="category('all')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>

            <div class="hidden-sm-and-up">
            <v-card-title id="ctxs" class="justify-center">Batik</v-card-title>
            <v-card-actions id="ct1xs" class="justify-center"><v-btn id="border1xs" @click="category('all')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>
          </v-img>
          
        </v-card>
        </v-col>
          
        <v-col cols="12" md="6" lg="3">
        <v-card flat class="mx-auto" max-width="600" min-width="300">
          <v-img contain width="100%" max-height="600" min-height="350" :src="homeSettingsdata.imggodes">
            <div class="hidden-xs-only">
            <v-card-title id="ct" class="justify-center text-no-wrap"><p class="ma-0 pe-2 pb-2">Goddess </p><span class="text-no-wrap"> Collection</span></v-card-title>
            <v-card-actions id="ct1" class="justify-center"><v-btn id="border1" @click="category('all')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>

            <div class="hidden-sm-and-up">
            <v-card-title id="ctxs" class="justify-center"><p class="ma-0 pe-2 pb-2">Goddess </p><span class="text-no-wrap"> Collection</span></v-card-title>
            <v-card-actions id="ct1xs" class="justify-center"><v-btn id="border1xs" @click="category('all')" outlined>SEE COLLECTION <v-icon dark right>mdi-arrow-right-thin</v-icon></v-btn></v-card-actions>
            </div>
          </v-img>
        </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="mt-15 mb-12">
      <v-card flat min-width="315" class="mx-4">
        <div>
        <p class="pa-3 ma-0 text-center" id="fhome">Lookbook</p>
        </div>

        <div>
        <p class="ma-0 text-center" id="fhome2">#dressedinNataliaSoetjipto to get featured</p>
        </div>
      </v-card>
    </div>

    <div class="mb-15">
      <v-row class="mx-2 justify-center">
        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150"> 
          <v-img width="100%" max-height="350" min-height="150" :src="homeSettingsdata.imglookbook1">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150"  :src="homeSettingsdata.imglookbook2">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150"  :src="homeSettingsdata.imglookbook3">
          </v-img>
        </v-card>
        </v-col>
          
        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150"  :src="homeSettingsdata.imglookbook4">
          </v-img>
        </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="mt-5 mb-15">
      <v-card flat min-width="350" width="100%" height="auto" class="d-flex justify-center">
        <v-carousel
        height="550"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
        >
          <v-carousel-item
            v-for="testimony in listTestimony"
            :src="testimony.img"
            :key="testimony.id"
            max-width="100%"
            height="auto"
            style = "transition: 1s ease;"
          >
            <div class="d-flex align-center justify-center fill-height">
              <v-card id="hcard" class="pt-15 pb-12 px-15">
                <p class="pa-0 ma-0 text-center" id="fhome2">{{ testimony.desctext }}
                  <br><br><br>
                <span style="font-size: 14px;">HAPPY BRIDE</span><br>
                <span id="fhome" style="font-size: 32px;">{{ testimony.name }}</span></p>
              </v-card>
            </div>
          </v-carousel-item>

        
        </v-carousel>
      </v-card>
    </div>

    <div>
      <v-row id="hrow" class="">
          <v-col class="" cols="12" sm="5" md="7" lg="7">
            <v-card flat class="mx-auto px-5" max-width="950" min-width="350">
              <v-img class="" width="100%"  :src="homeSettingsdata.imgdeschome"></v-img>
            </v-card>
          </v-col>

          <v-col class="px-5" cols="12" sm="7" md="5" lg="3">
            <v-card flat id="hcard2" class="mx-auto pt-10" min-width="365" max-width="450">
              <div>
              <p class="pa-3 ma-0 text-no-wrap" id="fhomes"> {{ homeSettingsdata.titlehome2 }}</p>
              </div>

              <div>
              <p class="pa-3 ma-0 text-justify " id="fhome2">
                {{ homeSettingsdata.deschome2 }}      
              <br><br></p>
              </div>

              <v-card-actions  class="justify-start">
                <v-btn id="fhome3" style="border-radius:50px;" class="" outlined>
                  <a style="text-decoration: none; color: grey;" target="_blank" href="https://wa.me/6285320007699">BOOK AN APPOINTMENT</a>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
      </v-row>
    </div>

    <div class="mt-15">
      <v-card id="hcard3" min-width="350">
      <div class="pt-15 pb-12 text-center">
          <p style="font-size:18px;" id="fhome2">FEATURED ON</p>
      </div>

      <v-row class="mt-8 pb-15 mb-0 text-center justify-center">
        <div v-for="feature in listFeatureon"
         :key="feature.id"
        >
        <v-col class="" cols="2">
            <img class="my-auto mx-auto" width="320" height="140" :src="feature.img">
        </v-col> 
        </div>
        
        </v-row>
        
      </v-card>
    </div>

    <div>
      <Footer></Footer>
    </div>

  </div>  

</template>

<script>
import footer from '../components/Footer.vue';
import header from '../components/Header.vue';
import axios from 'axios';
import constant from '@/util/constant';

  export default {
    name: 'home-page',
    components : {
      'Header':header,
      'Footer':footer
    },

  data (){
    return{
      listCarousel:[],
      homeSettingsdata:{},
      listFeatureon:[],
      listTestimony:[]
    }
  },

  methods : {
    category(type){
      this.$router.push('/category/' + type)
    },
    getCarousel(){

      let a = this

      axios.get(constant.BASE_URL + "carousel")
      .then(function (response) {
        console.log(response)
        a.listCarousel = response.data.data

      }).catch(function (error) {
        console.log(error);
  
      })

    },
    getFeatureon(){

      let a = this

      axios.get(constant.BASE_URL + "featureon")
      .then(function (response) {
        console.log(response)
        a.listFeatureon = response.data.data

      }).catch(function (error) {
        console.log(error);
      })

    },
    geTestimony(){

      let a = this

      axios.get(constant.BASE_URL + "testimony")
      .then(function (response) {
        console.log(response)
        a.listTestimony = response.data.data

      }).catch(function (error) {
        console.log(error);
    
      })

    },
    getHomeSettings(){

      let a = this
    
      axios.get(constant.BASE_URL + "settingshome")
      .then(function (response) {
        console.log(response)
        a.homeSettingsdata = response.data.data[0]

      }).catch(function (error) {
        console.log(error);

      })

    },
    
    process(){
      
    }
  },

  created(){
    this.getHomeSettings()
    this.getCarousel()
    this.getFeatureon()
    this.geTestimony()
    
  }

  }

</script>

<style scoped>
.body{
  padding:0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:man;
}

img {
  background: url(http://goo.gl/vyAs27) no-repeat scroll 0 0;
}

#hrow{
  justify-content:center;
}

#hcard{
  background: rgba(255,255,255,0.7);
}

#hcard2{
  min-height:605px;
}

#hcard3{
  background: #F5F5F5;
}

#hcard4{
  background: transparent;
}

#hcol{
  display:flex;
  align-items:center;
  justify-content:center;
}

#fhome{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 54px;
  color: #414141;
  
}

#fhomes{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  color: #414141;
}

#fhome2{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);

}

#fhome3{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);
}

#fhome4{
  font-family: lor;
  font-style: italic;
  font-weight: 600;
  font-size: 64px;
  line-height: 90px;
  color: #FFFFFF;
}


#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#margin{
  margin-top: 50px;
  margin-bottom: 20px;
}

#margin2{
  margin-right: 15%;
  margin-left: 15%;
}

#ct{
  position: absolute;
  bottom: 25%;
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: white;
  font-family: lor;
  font-style: italic;
  font-weight: 600;
}

#ctxs{
  position: absolute;
  bottom: 25%;
  text-align: center;
  width: 100%;
  font-size: 35px;
  color: white;
  font-family: lor;
  font-style: italic;
  font-weight: 600;
}

#ct1{
  position: absolute;
  bottom: 15%;
  text-align: center;
  width: 100%;
}

#ct1xs{
  position: absolute;
  bottom: 15%;
  text-align: center;
  width: 100%;
}

#list{
  float: right;
  padding: 5px 10px;
  overflow: hidden;
}

#bgcolor{
  background-color: rgba(0, 0, 0, 0.82);
}

#bg{
  background-color:  rgba(0, 0, 0, 0.82);
  margin-left: 0;
  margin-right: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
}

#hr2{
  height: 5px;
  background-color: rgba(124, 118, 118, 0.4);;
  margin-top: 50px;
  margin-bottom: 50px;
}

#h1{
  font-size: 50px;
}

#h1xs{
  font-size: 30px;
}

#hh1{
  letter-spacing: 5px;
}

#p1{
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

#p1xs{
  font-size: 15px;
  padding: 2px 2px;
  text-align: center;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

#border{
  border-radius: 20px;
}

#border1{
  border-radius: 50px;
  border:1px solid;
  font-size: 15px;
  padding: 15px 20px;
  color: white;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.08em;
}

#border2{
  border-radius: 50px;
  border:1px solid;
  font-size: 18px;
  padding: 15px 20px;
  color: white;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.08em;
}

#border1xs{
  border-radius: 50px;
  border:1px solid;
  font-size: 15px;
  padding: 10px 20px;
  color: white;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.08em;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

/* .imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
} */

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imgLogo2{
  display: block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 450px;
  min-width: 335px;
  height: auto;
  width: 100%;
}

.imgHome{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

@media only screen and (max-width:1264px){
  #h1{
    font-size: 40px;
  }

  #p1{
    font-size: 18px;
  }

  #border1{
    border:1px solid;
  }
}

@media only screen and (max-width:960px){
  #h1{
    font-size: 30px;
  }

  #p1{
    font-size: 15px;
  }

  #fhome{
    font-size: 40px;
    white-space:pre-line;
  }

  #fhomes{
    font-size: 30px;
  }

  #fhome2{
    font-size: 18px;
    white-space:pre-line;
  }
}

@media only screen and (max-width:600px){
  #fhome{
    font-size: 30px;
    white-space:pre-line;
  }

  #fhome2{
    font-size: 15px;
    white-space:pre-line;
  }

  #fhome4{
    font-size: 52px;
  }

  #hcard2{
    min-height:465px !important; 
  }

  #hcol{
    display:block;
  }
  
}


</style>