<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>


<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style scoped>
@font-face{
  font-family: inter;
  src: url(@/font/Inter/Inter-Regular.ttf);
}

@font-face{
  font-family: gara;
  src: url(@/font/Garamond/EBGaramond-Regular.ttf);
}

@font-face{
  font-family: futura;
  src: url(@/font/Futura/Futura\ Book\ font.ttf);
}

@font-face{
  font-family: roboto;
  src: url(@/font/Roboto/Roboto-Regular.ttf);
}

@font-face{
  font-family: intre;
  src: url(@/font/Intrepid/intrepid.ttf);
}

@font-face{
  font-family: mont;
  src: url(@/font/Montserrat/Montserrat-Regular.ttf);
}

@font-face{
  font-family: opensans;
  src: url(@/font/OpenSans/OpenSans-Regular.ttf);
}

@font-face{
  font-family: arvo;
  src: url(@/font/Arvo/Arvo-Regular.ttf);
}

@font-face{
  font-family: josef;
  src: url(@/font/Josefin/JosefinSans-Regular.ttf);
}

@font-face{
  font-family: meri;
  src: url(@/font/Merri/Merriweather-Regular.ttf);
}

@font-face{
  font-family: nuni;
  src: url(@/font/Nunito/Nunito-Regular.ttf);
}

@font-face{
  font-family: pop;
  src: url(@/font/Poppins/Poppins-Regular.ttf);
}

@font-face{
  font-family: quic;
  src: url(@/font/Quicksand/Quicksand-Regular.ttf);
}

@font-face{
  font-family: rig;
  src: url(@/font/Righteous/Righteous-Regular.ttf);
}

@font-face{
  font-family: ros;
  src: url(@/font/RobotoS/RobotoSlab-Regular.ttf);
}

@font-face{
  font-family: lor;
  src: url(@/font/Lora/Lora-Regular.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face{
  font-family: lor;
  src: url(@/font/Lora/Lora-Italic.ttf);
  font-style: italic;
  font-weight: normal;
}

@font-face{
  font-family: lor;
  src: url(@/font/Lora/Lora-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}

@font-face{
  font-family: lor;
  src: url(@/font/Lora/Lora-BoldItalic.ttf);
  font-style: italic;
  font-weight: bold;
}

@font-face{
  font-family: lor;
  src: url(@/font/Lora/Lora-SemiBoldItalic.ttf);
  font-style: italic;
  font-weight: 600;
}

@font-face{
  font-family: man;
  src: url(@/font/Manrope/Manrope-Regular.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face{
  font-family: man;
  src: url(@/font/Manrope/Manrope-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}


</style>
