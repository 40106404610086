<template>
  <div class="body fill-height">
    <div>
    <Header></Header>
    </div>

    <div>

      <div class="d-flex flex-column">
        <h1 class="mx-auto" style="font-family: man;">My Shopping Bag</h1>

        <div class="d-flex" style="padding: 50px;">
            
          <div>
            <h2 style="font-family: man;">Item Summary</h2>

            <div class="d-flex justify-space-between">
              <p>Item Details</p>
              <p>Price</p>
              <p>Quantity</p>
              <p>Total</p>
            </div>

            <v-divider></v-divider>

          </div>

          <div>
            <h2 style="font-family: man;">Order Summary</h2>


          </div>

        </div>

        <div style="text-align: center; margin-top: 40px;">
          <h1 style="font-family: man;">Recently Viewed</h1>
        </div>

        <div class="d-flex flex-row">

          <v-col class="d-flex flex-column col-3" v-for="item in itemlike" :key="item">
    
            <img style="max-height: 400px;" src="../assets/streetmodel.png" />

            <p style="font-size: 20px; margin-top: 10px;">{{ item }}</p>

            <p style="margin-top: -20px;">Rp. 500.000</p>

          </v-col>

        </div>
      </div>


    </div>

    <div>
    <Footer></Footer>
    </div>

  </div>
</template>

<script>

import footer from '../components/Footer.vue';
import header from '../components/Header.vue';
import axios from 'axios';
import constant from '../util/constant';
import Swal from 'sweetalert2'


export default {
  name: 'cart-page',
  components : {
      'Header':header,
      'Footer':footer
    },

    data(){
      return{
        page: 1,
        ischeckall:false,
        searchs:"",
        singleSelect: false,
        selected: [],
        totalprice: 0,
        itemlike:["AKSARA OUTER","AKSARA OUTER","AKSARA OUTER","AKSARA OUTER"],
        headers: [
        { text: '', value: 'ischeck',  class: 'grey darken-1',    align:"center", width:50   },
          {
            text: 'Item',
            value: 'item',
            class: 'grey darken-1',
            align:"center",
          },
          { text: 'Size', value: 'size',  class: 'grey darken-1', align:"center",sortable:false,},
          { text: 'Quantity', value: 'quantity',     class: 'grey darken-1', align:"center",sortable:false,},
          { text: 'Total Price', value: 'price',     class: 'grey darken-1' , align:"center",sortable:false,},
          { text: 'Action', value: 'action',     class: 'grey darken-1 mb-10', align:"center",sortable:false, },
        ],
        desserts: [],
      }
    },
    created(){
     this.getData()
    },
    methods: {

      checkout(){
      this.$router.push('/checkout')
      },
      getData(){

        var userid = localStorage.getItem("userid");
      if(userid == null){
        this.$router.push('/login')
      }else{

       let a = this

      this.desserts = []
  

        axios.get(constant.BASE_URL + "cart/" + userid)
      .then(function (response) {
     
        var item = response.data.data

        console.log(item)
        for(let i = 0; i < item.length; i++){

          let newitem = item[i]
    
          a.desserts.push(
            {
            "id":newitem.id,  
            "ischeck":false,
            "item": '',
            "size": newitem.size,
            "quantity": newitem.qty,
            "price": newitem.totalprice,
            "priceitem":0,
            "remaining": 1,
            "image" : "",
            "index": i
          })
     

          a.getProductbyId(newitem.productid, i)
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      }
      },

      removeItem(id){

        Swal.fire({
            title: "<h3 style='font-family:ros;'>Continue Remove Item?</h3>",

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "<h3 style='font-family:ros;'>Remove</h3>",
            cancelButtonText: "<h3 style='font-family:ros;'>Cancel</h3>"
          }).then((result) => {
            if (result.isConfirmed) {
           
        let a = this

              axios.delete( constant.BASE_URL + 'cart/' + id)
              .then(function (response) {

              console.log(response);

              Swal.fire({
              icon: 'success',
              title: "<h3 style='font-family:ros;'>Berhasil dihapus</h3>",
              showConfirmButton: false,
              })

              a.getData()
                
              }).catch(function (error) {

              Swal.fire({
                icon: 'error',
                title: error
              })
              console.log(error);
              });
            }
          })
      },

      getProductbyId(id, index){

        let a = this

        axios.get(constant.BASE_URL + "product/" + id)
      .then(function (response) {
      
        let item = response.data.data[0]
        let listimg = item.listphotos.split(',')

        console.log(listimg[0])
        a.desserts[index].item = item.name
        a.desserts[index].remaining = item.qty
        a.desserts[index].image = listimg[0]
        a.desserts[index].priceitem = parseInt(item.price)

      })
      .catch(function (error) {
        console.log(error);
      })

      },

      search(inputtext){
        console.log(inputtext)
      },

    }
}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}



@media only screen and (max-width: 600px) {


}


</style>