<template>
  <div class="body fill=height">

    <Header></Header>
    <v-divider class="divider"></v-divider>

  <div class="d-flex flex-row mb-12">
  

  <v-card elevation="0" style="background-color: #D9D9D9;" >
    <v-layout>
      <v-navigation-drawer
        class="navdrawer"
        theme="dark"
        permanent
        left
      >

      <div class="d-flex flex-column mb-6 bg-surface-variant heightbar">
        <!-- <img class="ma-2 pa-2" src="../assets/natsoe.png"> -->
        <v-btn @click="account" class="ma-2 pa-2 btndrawer">Account</v-btn>
        <!-- <v-btn @click="order" class="ma-2 pa-2 btndrawer">Order</v-btn> -->
        <v-btn @click="product" class="ma-2 pa-2 btndrawer">Product</v-btn>
        <v-btn @click="slider" class="ma-2 pa-2 btndrawer">Slider</v-btn>
        <v-btn @click="homeSettings" class="ma-2 pa-2 btndrawer">Home</v-btn>
        <v-btn @click="testimony" class="ma-2 pa-2 btndrawer">Testimony</v-btn>
        <v-btn @click="featureon" class="ma-2 pa-2 btndrawer">Feature On</v-btn>
        <v-btn @click="aboutsettings" class="ma-2 pa-2 btndrawer">About</v-btn>
      </div>

      </v-navigation-drawer>
    </v-layout>
  </v-card>


       <v-dialog
          v-model="dialogLoading"
          hide-overlay
          
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
 

  <template>
  <v-sheet
    class="overflow-hidden"
    style="position: relative;"
    width = "100vw"
    >

    <v-btn
    class="ms-3 mb-2 tgldrawer"
    dark
    style="margin-top: 20px;"
    @click.stop="drawer = !drawer">
    Select Table
  </v-btn>

  <!-- ACCOUNT PAGE -->
  <v-container v-if="acc" >
  <div style="border:5px solid black;border-radius: 20px;" class="content">
  <v-data-table
    :headers="headersAcc"
    :items="desserts"
    sort-by="calories"
    class="elevation-0"
    >
    <template v-slot:top>
      <v-toolbar
        flat
        >
        <v-toolbar-title><b>Account</b></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemAcc.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemAcc.password"
                      label="Password"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemAcc.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>      

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-select
                      v-model="editedItemAcc.role"
                      :items="itemuser"
                      label="Role"
                    ></v-select>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
  </v-container>

  <!-- PRODUCT PAGE -->
  <v-container v-if="pro">
  <div style="border:5px solid black;border-radius: 20px;" class="content">
  <v-data-table
    :headers="headersPro"
    :items="desserts"
    sort-by="calories"
    class="elevation-0"
    >

    
    <template v-slot:[`item.price`]="{ item }">

        <p>{{ formatRupiah(item.price) }}</p> 

    </template>

    <template v-slot:top>
      <v-toolbar
        flat
        >
        <v-toolbar-title><b>Product</b></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.price"
                      label="Price"
                      type="number"
                    ></v-text-field>
                  </v-col>

                          
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.color"
                      label="Color"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.size"
                      label="Size"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.qty"
                      label="Quantity"
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-select
                      v-model="editedItemPro.category"
                      label="Category"
                      :items="itemCategory"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.syle"
                      label="Style"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.productdetail"
                      label="Product Detail"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.linkshopee"
                      label="Link Shopee"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.linktokped"
                      label="Link Tokped"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.linklazada"
                      label="Link Lazada"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.linkzalora"
                      label="Link Zalora"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemPro.material"
                      label="Material"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-select
                      v-model="editedItemPro.type"
                      label="Type"
                      :items="itemType"
                    ></v-select>
                  </v-col>

                </v-row>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <div class="d-flex">
                      <div
                      v-for="card in editedlistphoto"
                      :cols="6"
                      style="margin: 4px;" 
                      :key="card">
                      <v-img
                      @click="deleteImage(card)"
                      style="cursor: pointer;"
                      :src="card"  
                      width="100"
                      height="100">
                      </v-img>    
                    </div>
                    </div>
                
                  </v-col>

                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >

                  <label style="cursor:pointer;"> Add Photo
                    <input  type="file" accept="image/*" id="file" ref="file" v-on:change="uploadFile()"/>
                  </label>
            
                  </v-col>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.listphotos`]="{ item }">

      <div class="d-flex">
        <div
        v-for="card in item.listphotos.split(',')"
        :cols="6"
         style="margin: 4px;" 
        :key="card">
        <v-img
        :src="card"  
        width="100"
        height="100">
        </v-img>    
      </div>
      </div>

    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
  </v-container>

  <!-- SLIDER PAGE -->
  <v-container v-if="sliders">
    <div style="border:5px solid black;border-radius: 20px;" class="content">
  <v-data-table
    :headers="headerCarousel"
    :items="desserts"
    class="elevation-0"
    >
    <template v-slot:top>
      <v-toolbar
        flat
        >
        <v-toolbar-title><b>Slider</b></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemSlider.title"
                      label="Title"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemSlider.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemSlider.descbtn"
                      label="Button Text"
                    ></v-text-field>
                  </v-col>     

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                  
                  <v-img
                      style="cursor: pointer; background-color: #D9D9D9;"
                      :src="editedItemSlider.img" 
                      width="100"
                      height="100">
                  </v-img>   
                  <br>
                  <label class="file-label" style="cursor:pointer;" > Change
                    <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('editslider')"/>
                  </label>    

                  <br>
                  </v-col>

         
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.img`]="{ item }">

      <v-img
          :src="item.img"  
          width="100"
          height="100">
          </v-img>   

        </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
  </v-container>

  <!-- HOME PAGE -->
  <v-container v-if="homes">
    <div style="border:5px solid black;border-radius: 20px;" class="content">
      <v-toolbar-title><b>Home</b></v-toolbar-title>
      <br>
      <v-text-field
            v-model="homeSettingsdata.titlehome"
            solo
      ></v-text-field>

      <v-text-field
            v-model="homeSettingsdata.deschome"
            solo
      ></v-text-field>  

       <v-text-field
            v-model="homeSettingsdata.titlehome2"
            solo
      ></v-text-field> 

        <v-text-field
            v-model="homeSettingsdata.deschome2"
            solo
      ></v-text-field>  

        <v-img
          style="cursor: pointer;"
          :src="homeSettingsdata.imgdeschome"  
          width="150"
          height="150">
        </v-img>  

        <br>

        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgdeschome')"/>
        </label>    

        <br>

  
        <v-row style="margin-top: 30px;">

          <v-col>

          <v-img
          style="cursor: pointer;"
        :src="homeSettingsdata.imgbride"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgbride')"/>
        </label>    

        <br>

      </v-col>


      <v-col>
        <v-img
        style="cursor: pointer;"
        :src="homeSettingsdata.imgparty"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgparty')"/>
        </label>    

        <br>
      </v-col>

      <v-col>
        <v-img
        style="cursor: pointer;"
        :src="homeSettingsdata.imgbatik"  
        width="150"
        height="150">
        </v-img>  
        <br>
    
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgbatik')"/>
        </label>    

        <br>
      </v-col> 

      <v-col>
        <v-img
        style="cursor: pointer;"
        :src="homeSettingsdata.imggodes"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imggodes')"/>
        </label>    

        <br>
      </v-col>

        </v-row>

       <v-text-field
        style="margin-top: 30px;"
            v-model="homeSettingsdata.linkshop1"
            solo
      ></v-text-field> 

        <v-text-field
            v-model="homeSettingsdata.linkshop2"
            solo
      ></v-text-field>  

       <v-text-field
            v-model="homeSettingsdata.linkshop3"
            solo
      ></v-text-field>  

       <v-text-field
            v-model="homeSettingsdata.linkshop4"
            solo
      ></v-text-field> 

      <v-row>

        <v-col>
          <v-img
          style="cursor: pointer;"
        :src="homeSettingsdata.imglookbook1"  
        width="150"
        height="150">
        </v-img>  
        <br>
        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imglookbook1')"/>
        </label>    

        <br>
        </v-col>

        <v-col>
          <v-img
          style="cursor: pointer;"
        :src="homeSettingsdata.imglookbook2"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imglookbook2')"/>
        </label>    

        <br>
          
        </v-col>

        <v-col>
          <v-img
          style="cursor: pointer;"
        :src="homeSettingsdata.imglookbook3"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imglookbook3')"/>
        </label>    

        <br>
          
        </v-col>

        <v-col>
          
        <v-img
        style="cursor: pointer;"
        :src="homeSettingsdata.imglookbook4"  
        width="150"
        height="150">
        </v-img>  
        <br>

        
        <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imglookbook4')"/>
        </label>    

        <br>
          
        </v-col>

      </v-row>

        <v-text-field
         style="margin-top: 30px;"
            v-model="homeSettingsdata.email"
            solo
      ></v-text-field>  

       <v-text-field
            v-model="homeSettingsdata.whatsapp"
            solo
      ></v-text-field>  

       <v-text-field
            v-model="homeSettingsdata.address"
            solo
      ></v-text-field>

      <div style="text-align: center;">
        <v-btn @click="updateHomeSettings" color="primary">Save Update</v-btn>
      </div>
    
      <br>

    </div>
  </v-container>

  <!-- TESTIMONY PAGE -->
  <v-container v-if="testimonies">
    <div style="border:5px solid black;border-radius: 20px;" class="content">
  <v-data-table
    :headers="headerTestimony"
    :items="desserts"
    class="elevation-0"
    >
    <template v-slot:top>
      <v-toolbar
        flat
        >
        <v-toolbar-title><b>Testimony</b></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemTestimony.desctext"
                      label="Description"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      v-model="editedItemTestimony.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >

                  <v-img
                      style="cursor: pointer; background-color: #D9D9D9;"
                      :src="editedItemTestimony.img" 
                      width="100"
                      height="100">
                  </v-img>   
                  <br>
                  <label class="file-label" style="cursor:pointer;" > Change
                    <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('edittestimony')"/>
                  </label>  

                  </v-col>

                

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.img`]="{ item }">

<v-img
    :src="item.img"  
    width="100"
    height="100">
    </v-img>   

  </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
  </v-container>

  <!-- FEATURE PAGE -->
  <v-container v-if="features">
    <div style="border:5px solid black;border-radius: 20px;" class="content">
  <v-data-table
    :headers="headerFeature"
    :items="desserts"
    class="elevation-0"
    >
    <template v-slot:top>
      <v-toolbar
        flat
        >
        <v-toolbar-title><b>Featured</b></v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  

                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    >

                  <v-img
                      style="cursor: pointer; background-color: #D9D9D9;"
                      :src="editedItemFeature.img" 
                      width="100"
                      height="100">
                  </v-img>   
                  <br>
                  <label class="file-label" style="cursor:pointer;" > Change
                    <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('editfeature')"/>
                  </label>  

                  </v-col>
              

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
                >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    
    <template v-slot:[`item.img`]="{ item }">

   <v-img
    :src="item.img"  
    width="150"
    height="150">
    </v-img>   

  </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </div>
  </v-container>

  <!-- ABOUT PAGE -->
  <v-container v-if="abouts">
    <div style="border:5px solid black;border-radius: 20px;" class="content">
      <v-toolbar-title><b>About</b></v-toolbar-title>
      <br>
      <v-text-field
            v-model="aboutSettingsdata.title1"
            solo
      ></v-text-field>

      <v-text-field
            v-model="aboutSettingsdata.title2"
            solo
      ></v-text-field>

      <v-text-field
            v-model="aboutSettingsdata.desc1"
            solo
      ></v-text-field>

      <v-text-field
            v-model="aboutSettingsdata.desc2"
            solo
      ></v-text-field>

      <v-text-field
            v-model="aboutSettingsdata.desc3"
            solo
      ></v-text-field>
      
      <v-row>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img1"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout1')"/>
        </label>    

        <br>
          </v-col>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img2"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout2')"/>
        </label>    

        <br>
          </v-col>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img3"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout3')"/>
        </label>    

        <br>
          </v-col>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img4"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout4')"/>
        </label>    

        <br>
          </v-col>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img5"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout5')"/>
        </label>    

        <br>
          </v-col>

          <v-col>
            <v-img
            style="cursor: pointer;"
          :src="aboutSettingsdata.img6"  
          width="150"
          height="150">
          </v-img>  
          <br>

          <label class="file-label" style="cursor:pointer;" > Change
          <input  style="" color="primary" class="hide-file-input" type="file" accept="image/*" id="file" ref="file" v-on:change="changeImage('imgabout6')"/>
        </label>    

        <br>
          </v-col>

          </v-row>

          <div style="text-align: center; margin-top: 30px;">
        <v-btn @click="updateAboutSettings" color="primary">Save Update</v-btn>
      </div>
    
      <br>
 
    </div>
  </v-container>


  <v-navigation-drawer
    style="background-color: #D9D9D9;"
    v-model="drawer"
    absolute
    temporary
    >

    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <!-- <img class="ma-2 pa-2" src="../assets/natsoe.png"> -->
      <v-btn @click="account" class="ma-2 pa-2 btndrawer">Account</v-btn>
      <!-- <v-btn @click="order" class="ma-2 pa-2 btndrawer">Order</v-btn> -->
      <v-btn @click="product" class="ma-2 pa-2 btndrawer">Product</v-btn>
      <v-btn @click="slider" class="ma-2 pa-2 btndrawer">Slider</v-btn>
      <v-btn @click="homeSettings" class="ma-2 pa-2 btndrawer">Home</v-btn>
      <v-btn @click="testimony" class="ma-2 pa-2 btndrawer">Testimony</v-btn>
      <v-btn @click="featureon" class="ma-2 pa-2 btndrawer">Feature On</v-btn>
      <v-btn @click="aboutsettings" class="ma-2 pa-2 btndrawer">About</v-btn>

    </div>

  </v-navigation-drawer>
  </v-sheet>
  </template>

    </div>
      <Footer></Footer>
    </div>
</template>

<script>
import footer from '../components/Footer.vue';
import header from '../components/Header.vue';
import axios from 'axios';
import constant from '@/util/constant';
import Swal from 'sweetalert2'

export default {
  name: 'admin-page',
  components : {
    'Header':header,
    'Footer':footer
  },

  data (){
    return{
      acc: true,
      ord: false,
      pro: false,
      homes:false,
      abouts:false,
      sliders:false,
      features:false,
      testimonies:false,
      drawer: null,
      cards: [
        { title: 'Pre-fab homes', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4},
        { title: 'Favorite road trips', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
        { title: 'Best airlines', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
        { title: 'Pre-fab homes', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4},
        { title: 'Favorite road trips', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
        { title: 'Best airlines', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
        { title: 'Pre-fab homes', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4},
        { title: 'Favorite road trips', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
        { title: 'Best airlines', src: 'https://images.tokopedia.net/img/cache/500-square/VqbcmM/2021/10/5/a6f494f1-69f3-43d5-b462-c04ffdde0537.jpg', flex: 4 },
      ],
      page: 1,
      dialog: false,
      dialogDelete: false,
      dialogLoading:false,
      deletedItem:{},
      itemuser:["admin", "user"],
      itemCategory:["newarival", "sale", "woman", "streetwear", "fashion", "modest"],
      itemType:["bridal", "party", "culture", "customization"],
      headersAcc: [
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        { text: 'Password', value: 'password' },
        { text: 'Name', value: 'name' },
        { text: 'Role', value: 'role'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      headersOrd: [
        {
          text: 'List ID Product',
          align: 'start',
          sortable: false,
          value: 'listidproduct',
        },
        { text: 'Date', value: 'date' },
        { text: 'Total Price', value: 'totalprice' },
        { text: 'Address', value: 'address' },
        { text: 'Status Order', value: 'statusorder' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersPro: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Description', value: 'description' },
        { text: 'Price', value: 'price' },
        { text: 'List Photos', value: 'listphotos' },
        { text: 'Color', value: 'color' },
        { text: 'Size', value: 'size'},
        { text: 'Quantity', value: 'qty'},
        { text: 'Category', value: 'category'},
        { text: 'Style', value: 'style'},
        { text: 'Product Detail', value: 'productdetail'},
        { text: 'Material', value: 'material'},
        { text: 'Type', value: 'type'},
        { text: 'Link Shopee', value: 'linkshopee'},
        { text: 'Link Tokped', value: 'linktokped'},
        { text: 'Link Lazada', value: 'linklazada'},
        { text: 'Link Zalora', value: 'linkzalora'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerCarousel:[
      {
          text: 'Image',
  
          sortable: false,
          value: 'img',
        },
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Button Text', value: 'descbtn'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerTestimony:[
      {
          text: 'Description',
          align: 'start',
          value: 'desctext',
        },
        { text: 'Name', value: 'name' },
        { text: 'Image', value: 'img' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headerFeature:[
      {
          text: 'Image',
          value: 'img',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
  
      desserts: [],
      editedlistphoto:[],
      editedIndex: -1,
      editedItemAcc: {},
      defaultItemAcc: {},
      editedItemOrd: {},
      defaultItemOrd: { },
      editedItemPro: {},
      defaultItemPro: {},
      file:"",
      homeSettingsdata:{},
      listFeatureon:[],
      listTestimony:[],
      aboutSettingsdata:{},
      editedItemSlider:{},
      editedItemFeature:{},
      editedItemTestimony:{}
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    account(){
      this.acc = true
      this.pro = false
      this.ord = false
      this.sliders = false
      this.homes = false
      this.abouts = false
      this.testimonies = false
      this.features = false
      this.getDataAcc()
    },
    product(){
      this.acc = false
      this.pro = true
      this.ord = false
      this.sliders = false
      this.homes = false
      this.abouts = false
      this.testimonies = false
      this.features = false
      this.getDataPro()
    },
    slider(){
      this.sliders = true
      this.acc = false
      this.pro = false
      this.homes = false
      this.abouts = false
      this.testimonies = false
      this.features = false
  
      this.getCarousel()

    },
    homeSettings(){
      this.homes = true
      this.acc = false
      this.pro = false
      this.sliders = false
      this.abouts = false
      this.testimonies = false
      this.features = false
      this.getHomeSettings()
    },
    testimony(){
      this.testimonies = true
      this.acc = false
      this.pro = false
      this.sliders = false
      this.abouts = false
      this.homes = false
      this.features = false
      this.geTestimony()
    },
    featureon(){
      this.features = true
      this.acc = false
      this.pro = false
      this.sliders = false
      this.abouts = false
      this.homes = false
      this.testimonies = false
      this.getFeatureon()
    },
    aboutsettings(){
      this.abouts = true
      this.acc = false
      this.pro = false
      this.sliders = false
      this.testimonies = false
      this.homes = false
      this.features = false

      this.getAboutSettings()
    },
    order(){
      this.acc = false
      this.pro = false
      this.ord = true
      this.getDataOrd()
    },
    getAll(){
      this.getDataAcc()
      this.getDataOrd()
      this.getDataPro()
    },

    getDataAcc() {
      this.dialogLoading = true
      axios.get ( constant.BASE_URL + 'account').then(response => {
        console.log(response)
        this.desserts = [];

        for (let i = 0; i <   response.data.data.length; i++) {

          var item = response.data.data[i];
          this.desserts.push(item)
        }

        console.log(this.desserts);
        this.dialogLoading = false

        }).catch(function(error) {
          console.log(error)
          this.dialogLoading = false
        })
    },

    getDataOrd() {
      this.dialogLoading = true
      axios.get ( constant.BASE_URL + 'order').then(response => {
        console.log(response)
        this.desserts = [];

        for (let i = 0; i <   response.data.data.length; i++) {

          var item = response.data.data[i];
          this.desserts.push(item)
        }

        console.log(this.desserts);
        this.dialogLoading = false

        }).catch(function(error) {
          console.log(error)
          this.dialogLoading = false
        })
    },

    getDataPro() {
      this.dialogLoading = true
      axios.get ( constant.BASE_URL + 'product').then(response => {
        console.log(response)
        this.desserts = [];

        for (let i = 0; i <   response.data.data.length; i++) {

          var item = response.data.data[i];
          this.desserts.push(item)
        }

        console.log(this.desserts);
        this.dialogLoading = false

        }).catch(function(error) {
          console.log(error)
          this.dialogLoading = false
        })
    },
    getCarousel(){
      this.dialogLoading = true
      let a = this

      axios.get(constant.BASE_URL + "carousel")
      .then(function (response) {

        a.desserts = [];

      for (let i = 0; i <   response.data.data.length; i++) {

        var item = response.data.data[i];
        a.desserts.push(item)
      }

      a.dialogLoading = false

      }).catch(function (error) {
        console.log(error);
        a.dialogLoading = false
      })

    },
    getFeatureon(){
      this.dialogLoading = true
      let a = this

      axios.get(constant.BASE_URL + "featureon")
      .then(function (response) {
        a.desserts = [];

      for (let i = 0; i <   response.data.data.length; i++) {

        var item = response.data.data[i];
        a.desserts.push(item)
    }

    console.log(this.desserts);
    a.dialogLoading = false

    }).catch(function (error) {
      console.log(error);
      a.dialogLoading = false
    })

    },
    geTestimony(){
      this.dialogLoading = true
      let a = this

      axios.get(constant.BASE_URL + "testimony")
      .then(function (response) {
        a.desserts = [];

        for (let i = 0; i <  response.data.data.length; i++) {

        var item = response.data.data[i];
        a.desserts.push(item)
    }

    console.log(this.desserts);
    a.dialogLoading = false

    }).catch(function (error) {
      console.log(error);
      a.dialogLoading = false
    })

    },
    getHomeSettings(){
      this.dialogLoading = true

      let a = this

      axios.get(constant.BASE_URL + "settingshome")
      .then(function (response) {
        console.log(response)
        a.homeSettingsdata = response.data.data[0]
        a.dialogLoading = false

      }).catch(function (error) {
        console.log(error);
        a.dialogLoading = false
      })

    },
    getAboutSettings(){
    this.dialogLoading = true
    let a = this

    axios.get(constant.BASE_URL + "settingsbout")
    .then(function (response) {
      console.log(response)
      a.aboutSettingsdata = response.data.data[0]
      a.dialogLoading = false
    }).catch(function (error) {
      console.log(error);
      a.dialogLoading = false
    })

    },
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      if(this.acc){
       this.editedItemAcc = item
      }
      if(this.pro){
        this.editedItemPro = item
        let listimg = item.listphotos.split(',')
        this.editedlistphoto = listimg
      }
      if(this.sliders){
        this.editedItemSlider = item
      }
      if(this.testimonies){
        this.editedItemTestimony = item
      }
      if(this.features){
        this.editedItemFeature = item
      }

      this.dialog = true
    },
    deleteItem (item) {
      console.log(item)
      this.deletedItem = item
      this.dialogDelete = true
    },

    deleteItemConfirm () {

      // delete item
      this.dialogLoading = true

      let a = this

      if(a.acc){
        axios.delete( constant.BASE_URL + 'account/'+ a.deletedItem.id)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data Dihapus</h3>",
            showConfirmButton: false,
          })
          a.getDataAcc()

          a.closeDelete()
        

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.getDataAcc()
          a.closeDelete()
        })

      }

      if(a.pro){

        axios.delete( constant.BASE_URL + 'product/'+ a.deletedItem.id)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data Dihapus</h3>",
            showConfirmButton: false,
          })
          a.getDataPro()
          a.closeDelete()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.getDataPro()
          a.closeDelete()
        })

      }

      if(a.sliders){

        
        axios.delete( constant.BASE_URL + 'carousel/'+ a.deletedItem.id)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data Dihapus</h3>",
            showConfirmButton: false,
          })
          a.getCarousel() 
          a.closeDelete()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.getCarousel()
          a.closeDelete()
        })

      }

      if(a.testimonies){

        
        axios.delete( constant.BASE_URL + 'testimony/'+ a.deletedItem.id)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data Dihapus</h3>",
            showConfirmButton: false,
          })
          a.geTestimony()
          a.closeDelete()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.geTestimony()
          a.closeDelete()
        })

      }

      if(a.features){

        
        axios.delete( constant.BASE_URL + 'featureon/'+ a.deletedItem.id)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data Dihapus</h3>",
            showConfirmButton: false,
          })
          a.getFeatureon()
          a.closeDelete()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.getFeatureon()
          a.closeDelete()
        })

      }


    },
    deleteImage(item){
      Swal.fire({
            title: "<h3 style='font-family:ros;'>Delete This image</h3>",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "<h3 style='font-family:ros;'>Delete</h3>",
            cancelButtonText: "<h3 style='font-family:ros;'>Cancel</h3>"
          }).then((result) => {
            if (result.isConfirmed) {
             const index = this.editedlistphoto.indexOf(item); // Find the index of the item to be removed
  
              if (index !== -1) {
                this.editedlistphoto.splice(index, 1); // Remove the item using splice
              }
            }
          })

    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
      this.editedIndex = -1
      this.editedItemAcc = {}
      this.editedItemPro = {}
      this.editedItemSlider = {}
      this.editedItemFeature = {}
      this.editedItemTestimony = {}
      this.editedlistphoto = []
      })
    },
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR'
      });
      return formatter.format(number);
    },

    closeDelete () {
      this.dialogDelete = false

    },
    uploadFile(){

    this.dialogLoading = true 

    this.file = this.$refs.file.files[0];

    let formData = new FormData();
    
    formData.append('file', this.file);
  
    let a = this

    axios.post(constant.BASE_URL + 'upload',
    formData,{headers: {
        'Content-Type': 'multipart/form-data'
       }}).then(function(response){
        a.editedlistphoto.push( response.data.data.secure_url )
        a.dialogLoading = false
        }).catch(function(){
          a.dialogLoading = false
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  'dokumen tidak valid'
         })
    });

    },
    save () {

      let a = this

      if (this.editedIndex > -1) {
        // update data
        if(a.acc){

        axios.put( constant.BASE_URL + 'account', a.editedItemAcc)
        .then(function (response) {
          console.log(response)
          

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })
          a.editedItemAcc = {}
          a.getDataAcc()

      
        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.editedItemAcc = {}
          a.getDataAcc()
  
        })

        }

        if(a.pro){

          a.editedItemPro.listphotos = a.editedlistphoto.toString()

          axios.put( constant.BASE_URL + 'product', a.editedItemPro)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })
          a.editedlistphoto = []
          a.editedItemPro = {}
          a.getDataAcc()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.editedlistphoto = []
          a.editedItemPro = {}
          a.getDataAcc()
       
        })

        }

        if(a.sliders){

          
          axios.put( constant.BASE_URL + 'carousel', a.editedItemSlider)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })

          a.editedItemSlider = {}
          a.getCarousel()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          
          a.editedItemSlider = {}
          a.getCarousel()
       
        })

        }

        if(a.testimonies){

          axios.put( constant.BASE_URL + 'testimony', a.editedItemTestimony)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })

          a.editedItemTestimony = {}
          a.geTestimony()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
       
          a.editedItemTestimony = {}
          a.geTestimony()
       
        })

        }

        if(a.features){
          axios.put( constant.BASE_URL + 'featureon', a.editedItemFeature)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })

          a.editedItemFeature = {}
          a.getFeatureon()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
       
          a.editedItemFeature = {}
          a.getFeatureon()
       
        })

        }

      }else{
        // insert data
        if(a.acc){

          axios.post( constant.BASE_URL + 'account', a.editedItemAcc)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil dibuat</h3>",
            showConfirmButton: false,
          })
          a.editedItemAcc = {}
          a.getDataAcc()


        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.editedItemAcc = {}
          a.getDataAcc()
  
        })

        }

        if(a.pro){

          a.editedItemPro.listphotos = a.editedlistphoto.toString()
  
          axios.post( constant.BASE_URL + 'product', a.editedItemPro)
           .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil dibuat</h3>",
            showConfirmButton: false,
          })
          a.editedlistphoto = []
          a.editedItemPro = {}
          a.getDataAcc()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.editedlistphoto = []
          a.editedItemPro = {}
          a.getDataAcc()

        })

        }

        if(a.sliders){

          axios.post( constant.BASE_URL + 'carousel', a.editedItemSlider)
           .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil dibuat</h3>",
            showConfirmButton: false,
          })
        
          a.editedItemSlider = {}
          a.getCarousel()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
     
          a.editedItemSlider = {}
          a.getCarousel()

        })

        }

        if(a.testimonies){

          axios.post( constant.BASE_URL + 'testimony', a.editedItemTestimony)
           .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil dibuat</h3>",
            showConfirmButton: false,
          })
        
          a.editedItemTestimony = {}
          a.geTestimony()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
     
          a.editedItemTestimony = {}
          a.geTestimony()

        })

        }

        if(a.features){

          axios.post( constant.BASE_URL + 'featureon', a.editedItemFeature)
           .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil dibuat</h3>",
            showConfirmButton: false,
          })
        
          a.editedItemFeature = {}
          a.getFeatureon()

        }).catch(function (error) {

          Swal.fire({
            icon: 'error',
            title: error
          })
     
          a.editedItemFeature = {}
          a.getFeatureon()

        })
          
        }

      }
      this.close()
    },

    changeImage(imgid){
      console.log(imgid)

      this.dialogLoading = true 

      this.file = this.$refs.file.files[0];

      let formData = new FormData();

      formData.append('file', this.file);

      let a = this

      axios.post(constant.BASE_URL + 'upload',
      formData,{headers: {
          'Content-Type': 'multipart/form-data'
        }}).then(function(response){
           var imageurl = response.data.data.secure_url

           switch(imgid) {
              case "imgdeschome":
                a.homeSettingsdata.imgdeschome = imageurl 
                break;
              case "imgbride":
                a.homeSettingsdata.imgbride = imageurl 
                break;
              case "imgparty":
                a.homeSettingsdata.imgparty = imageurl 
                break;
              case "imgbatik":
                a.homeSettingsdata.imgbatik = imageurl 
                break;
              case "imggodes":
                a.homeSettingsdata.imggodes = imageurl 
                break;
              case "imglookbook1":
                a.homeSettingsdata.imglookbook1 = imageurl 
                break;
              case "imglookbook2":
                a.homeSettingsdata.imglookbook2 = imageurl 
                break;   
              case "imglookbook3":
                a.homeSettingsdata.imglookbook3 = imageurl 
                break;    
              case "imglookbook4":
                a.homeSettingsdata.imglookbook4 = imageurl 
                break;  
              case "imgabout1":
                a.aboutSettingsdata.img1 = imageurl
                break;  
              case "imgabout2":
                a.aboutSettingsdata.img2 = imageurl
                break;   
              case "imgabout3":
                a.aboutSettingsdata.img3 = imageurl
                break;   
              case "imgabout4":
                a.aboutSettingsdata.img4 = imageurl
                break;   
              case "imgabout5":
                a.aboutSettingsdata.img5 = imageurl
                break;   
              case "imgabout6":
                a.aboutSettingsdata.img6 = imageurl
                break; 
              case "editslider":
                a.editedItemSlider.img = imageurl
                break;  
              case "edittestimony":
                a.editedItemTestimony.img = imageurl
                break;  
              case "editfeature":
                a.editedItemFeature.img = imageurl
                break;          
            }

          a.dialogLoading = false
    
        }).catch(function(){
            a.dialogLoading = false
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text:  'dokumen tidak valid'
          })
      });

    },

    updateHomeSettings(){

      this.dialogLoading = true

        let a = this

        axios.put(constant.BASE_URL + "settingshome", a.homeSettingsdata)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })
    
          a.dialogLoading = false

        }).catch(function (error) {
          console.log(error);

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.dialogLoading = false
        })

    },
    updateAboutSettings(){

      this.dialogLoading = true

        let a = this

        axios.put(constant.BASE_URL + "settingsbout", a.aboutSettingsdata)
        .then(function (response) {
          console.log(response)

          Swal.fire({
            icon: 'success',
            title: "<h3 style='font-family:ros;'>Data berhasil diubah</h3>",
            showConfirmButton: false,
          })

          a.dialogLoading = false

        }).catch(function (error) {
          console.log(error);

          Swal.fire({
            icon: 'error',
            title: error
          })
          a.dialogLoading = false
        })

    },
 
  
  },

  created(){

    let userRole = localStorage.getItem("role")

    if(userRole != "admin"){
      this.$router.push('/');
    }


    this.getDataAcc()
  },
}

</script>

<style lang="scss" scoped>
.body{
  font-family: ros;
}

.hide-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.file-label {
  color: #fff;
  background-color: #3730A3;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
input[type='file']:focus + .file-label {
  box-shadow: 0 0 0 4px #bae6fd;
}

.divider{
  border:1px solid rgba(0, 0, 0, 0.1);
}

.heightbar{
  height: 100vh;
}

.content{
  padding: 10px 20px;
}

.searcbar{
  padding:12px;
  width: 280px;
  height: 20px;
}

.search{
  margin:5px 15px;
  padding:5px;
  border-radius:20px;
  border: 2px solid;
}

.tgldrawer{
  display: none;
}

.searcbar::before{
  border-style: none !important;
}

.btndrawer{
  background-color: #2E2E2E !important;
  border-radius: 20px;
  color: white;
}

.navdrawer {
  background-color: white;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
.content{
  padding: 0px;
}
.search{
  width: 250px !important;
}

.pagination{
 margin-left: -200px;
}

.tgldrawer{
  display: block;
}

.navdrawer {
  display: none;
}
.carditem{
  margin: 8px !important;
}

}

</style>