<template>
    <div class="body">
      <div id="bg">
      <v-sheet min-width="320" flat style="background-color: transparent;color: white;">
        <div class="text-center">
          <h1 id="hh1">FIND US ON MARKETPLACE</h1>
        </div>

        <div>
          <v-row class="mt-8 mb-12 mx-10 text-center justify-center">
            
            <v-col class="" cols="6" sm="3" md="2" lg="1">
              <v-btn @click="goShop1" fab id="" height="80" width="80">
                <img  class="my-auto mx-auto" width="50" height="50" src="../assets/shopelogo.png">
              </v-btn>
            </v-col>
            
            <v-col class="" cols="6" sm="3" md="2" lg="1">
              <v-btn @click="goShop2" fab id="" height="80" width="80">
                <img class="my-auto mx-auto" width="50" height="50" src="../assets/tokpedlogo.png">
              </v-btn>
            </v-col>

            <v-col class="" cols="6" sm="3" md="2" lg="1">
              <v-btn @click="goShop3" fab id="" height="80" width="80">
                <img class="my-auto mx-auto" width="50" height="50" src="../assets/lazadalogo.png">
              </v-btn>
            </v-col>

            <v-col class="" cols="6" sm="3" md="2" lg="1">
              <v-btn @click="goShop4"  fab id="" height="80" width="80">
                <img class="my-auto mx-auto" width="50" height="50" src="../assets/zaloralogo.png">
              </v-btn>
            </v-col>
            
          </v-row>

          <hr class="line">


          <div class="hidden-xs-only">
            <v-row class="mt-10 mb-5 mx-10 justify-center align-center">
              <div>
              <v-col class="mx-5">
                <p  class="my-auto contact contact2"> <img class="my-auto imgcontact"  src="../assets/mail.png">{{ homeSettingsdata.email }}</p>
              </v-col>
              </div>

              <div>
              <v-col class="mx-5">
                <p  class="my-auto contact"><img class="my-auto imgcontact2" src="../assets/walogo.png">{{ homeSettingsdata.whatsapp }}</p>
              </v-col>
              </div>

              <div>
              <v-col class="mx-5">
                <p  class="my-auto contact"><img class="my-auto imgcontact"  src="../assets/shop.png"> {{ homeSettingsdata.address }}</p>
              </v-col>
              </div>
            </v-row>
          </div>

          <div class="hidden-sm-and-up">
            <v-row class="mt-10 mb-5 mx-10 justify-center">
              
              <v-col cols="12">
                <p  class="my-auto contact-xs contact2 text-no-wrap"> <img class="my-auto imgcontact"  src="../assets/mail.png">  NATSOE@GMAIL.COM</p>
              </v-col>
              <v-col cols="12">
                <p  class="my-auto contact-xs text-no-wrap"><img class="my-auto imgcontact2"  src="../assets/walogo.png">  +62 853 2000 7699</p>
              </v-col>
              <v-col cols="12">
                <p  class="my-auto contact-xs text-no-wrap"><img class="my-auto imgcontact"  src="../assets/shop.png">  TOKAN SOHO WATERPLACE TOWER C-6</p>
              </v-col>
              
            </v-row>
          </div>

          <div class="d-flex">
            <img class="mx-3 my-auto" width="20px" height="auto" src="../assets/c.png"> 
            <p id="p" class="my-auto">Natsoe</p>
          </div>
        </div>
        </v-sheet>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import constant from '@/util/constant';

export default {
  name: 'footer-page',

data (){
  return{
    homeSettingsdata:{},
 }
},
created(){

  this.getHomeSettings()
},
methods : {
  getHomeSettings(){

     let a = this

      axios.get(constant.BASE_URL + "settingshome")
      .then(function (response) {
        console.log(response)
        a.homeSettingsdata = response.data.data[0]

      }).catch(function (error) {
        console.log(error);

      })

},
goShop1(){
  window.open(this.homeSettingsdata.linkshop1, '_blank').focus();
},
goShop2(){
  window.open(this.homeSettingsdata.linkshop2, '_blank').focus();
},
goShop3(){
  window.open(this.homeSettingsdata.linkshop3, '_blank').focus();
},
goShop4(){
  window.open(this.homeSettingsdata.linkshop4, '_blank').focus();
},
  
}


}

</script>

<style lang="scss" scoped>
.body{
  position: relative;
  height: 100%;
  font-family:man;

}

.contact{
  color: white;
  font-size: 20px;
}

.contact-xs{
  color: white;
  font-size: 15px;
}

.contact2{
  letter-spacing: 0.08em;
 
}

.imgcontact{
  margin:10px 15px 10px 0px;
  height: 20px;
  width: 25px;
}

.imgcontact2{
  margin:10px 15px 10px 0px;
  height: 20px;
  width: 20px;
}

.line{
  height: 2px;
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
}

#bg{
  background-color:  rgba(0, 0, 0, 0.82);
  margin-left: 0;
  margin-right: 0;
  padding-top: 50px;
  padding-bottom: 5px;
}

#p{
  color: white;
  font-size:15px;
  font-weight:400;
}

#hh1{
  letter-spacing: 5px;
  font-size: 24px;
  color: white;
}

#border{
  border-radius: 20px;
}


</style>