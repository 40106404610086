<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <v-row class="my-auto d-flex"> 
        <v-col class="col1 bg">
          <div class="mb-10">
              <v-btn text id="button1" width="200" height="180"><img width="81" height="88" src="../assets/acc2.png" ></v-btn>
            </div>
            <div >
              <ul>
                <li><v-btn @click="account" id="button" block ><a href="">Profile</a></v-btn></li>
                <li><v-btn @click="accountPass" id="button" block ><a href="">Password</a></v-btn></li>
                <li><v-btn @click="myOrder" id="button" block ><a href="">My Order</a></v-btn></li>
              </ul>
            </div>
        </v-col>
        
        <v-col class="col2">
          <v-card min-width="270" class="col3" flat>
            <v-card-title class="px-0">
              <h1 class="hidden-xs-only">Change Password</h1>
              <h1 id="h1xs" class="hidden-sm-and-up">Change Password</h1>
            </v-card-title>

            <v-form 
            ref="form"
            lazy-validation>
              
              <div id="name">
                <v-text-field
                    class="my-auto"
                    prepend-inner-icon="mdi-lock-outline"
                    outlined
                    v-model="oldPassword"
                    label="Current Password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    name="input-10-1"
                    hint="At least 8 characters"
                    counter
                    @click:append="show1 = !show1"
                    required>
                </v-text-field>
              </div>
                
              <div>
                <v-text-field
                  class="my-auto"
                  prepend-inner-icon="mdi-lock-open-plus-outline"
                  outlined
                  v-model="password"
                  label="New Password"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  name="input-10-1"
                  hint="At least 8 characters"
                  counter
                  @click:append="show2 = !show2"
                  required
                ></v-text-field>
              </div>
             

              <div>
                <v-text-field
                  class="my-auto"
                  prepend-inner-icon="mdi-lock-plus-outline"
                  outlined
                  v-model="password2"
                  label="Confirm Password"
                  :type="show3 ? 'text' : 'password'"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  name="input-10-1"
                  hint="At least 8 characters"
                  counter
                  @click:append="show3 = !show3"
                  required
                ></v-text-field>
              </div>

              <div class="d-none">
                <v-text-field
                  class="my-auto"
                  prepend-inner-icon="mdi-lock-plus-outline"
                  outlined
                  v-model="password3"
                  label="Confirm Password"
                  :type="show4 ? 'text' : 'password'"
                  :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                  name="input-10-1"
                  hint="At least 8 characters"
                  counter
                  @click:append="show4 = !show4"
                  required
                ></v-text-field>
              </div>

              <div id="name1">
                <v-row>
                  <v-col class="d-flex justify-start">
                    <li><v-btn id="button" @click="clear"><a href="#">Cancel</a></v-btn></li>
                  </v-col>

                  <v-col class="d-flex justify-end">
                    <li><v-btn id="buttons" @click="save"><a href="#">Save</a></v-btn></li>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-col>
    </v-row>


  </div>
</template>

<script>
import header from '../components/Header.vue'
import axios from 'axios'
import constant from '@/util/constant'
import Swal from 'sweetalert2'


export default {
  name: 'account-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      oldPassword:"",
      password:"",
      password2:"",
      password3:"",
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      
    }
  },

  methods : {
    accountPass(){
      this.$router.push('/accountpass')
    },

    account(){
      this.$router.push('/account')
    },
    myOrder(){
      this.$router.push('/myorder')
    },

    clear(){
      this.oldPassword = "";
      this.password = "";
      this.password2 = "";
    },

    getData(){

      var userid = localStorage.getItem("userid");

      axios.get(constant.BASE_URL + 'account/' + userid).then(response => {
      console.log(response)
      let item = response.data.data[0]
      this.firstName =  item.firstname
      this.lastName = item.lastname
      this.email = item.email
      this.phone = item.phone
      this.address = item.address
      this.password3 = item.password
      this.role = item.role
      
      }).catch(function(error) {
        console.log(error)
      })
    },

    save(){
      let a = this
      if(a.password === a.password2 && a.password != "" && a.password2 != "" && a.password3 === a.oldPassword){

      var userid = localStorage.getItem("userid");
      
      axios.put(constant.BASE_URL + 'account', {
              
        id : userid,
        firstname : a.firstName,
        lastname : a.lastName,
        email : a.email,
        phone : a.phone,
        address : a.address, 
        password : a.password,
        role : a.role,

      }).then(response => {
      
      this.clear();
      Swal.fire({
      icon: 'success',
      title: "<h3 style='font-family:ros;'>Data Diperbarui</h3>",
      })
      console.log(response)
      a.getData()
      }).catch(function(error) {
        console.log(error)
        Swal.fire({
        icon: 'error',
        title: "<h3 style='font-family:ros;'>Gagal Diperbarui</h3>",
        })
      });
      }else if (a.password3 != a.oldPassword){
          Swal.fire({
            icon: 'error',
            title: "<h5 style='font-family:ros;'>Password Saat Ini Salah</h5>",
        })
        }else if (a.password == "" || a.password2 == "" || a.password == "" && a.password2 == ""){
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Password Kosong</h3>",
        })
        }else if (a.password != a.password2){
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Password Tidak Sama</h3>",
        })
        }else {
          Swal.fire({
            icon: 'error',
            title: "<h3 style='font-family:ros;'>Error Error</h3>",
        })
      }
    },


  },

  created(){
    this.getData()
  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

#h1xs{
  font-size: 30px;
}

#button{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
  background-color: black;
}

#buttons{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
  background-color: black;
}

#button1{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
}

#name{
  margin-top: 5vh;
}

#name1{
  margin-top: 30vh;
}

.bg{
  background-color: #D9D9D9;
}

.col1{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0%;
  flex-basis:25%;
  height:100vh;
  
  
}

.col2{
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis:75%;
  height:100vh;
  padding:10vh 10vw;
}

.col3{
  flex-grow:1;
}

.navBar{
  /* font-family: inter; */
  font-size: 30px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  /* background-color: rgba(0, 0, 0, 0.82) ; */
  color: black;
}

ul{
  padding:0px;
}

li{
  display: block;
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-size: 20px;
}

li a{
  color:white;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 3px;
  width:0;
  left: 0;
  bottom: -4px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

@media only screen and (max-height:768px){
  .col1{
    height:115vh; 
  }
}

</style>