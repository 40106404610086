<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <v-row class="my-auto justify-center pa-5"> 
      <v-col class="col1 px-5" cols="12" lg="6">
        <v-card min-width="300" flat class="my-3">
          <v-row>
            <v-col class="">
              <p id="ptitle" class="text-no-wrap ma-0">Shipping Detail</p>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat id="card1" class="mb-10" min-width="350">
          <v-row class="pa-2">
            <v-col class="col4 py-1 text-no-wrap">
              <p class="p2 my-auto">Naila Jardin</p>
            </v-col>
            
            <v-col class="col4 py-1 text-no-wrap">
              <p class="p2 my-auto">(+62) 891-1324-2311</p>
            </v-col>
            
            <v-col class="col4 py-1">
              <p class="p2 my-auto">Jalan Kutai Kertanegara 1 No. 22 (pagar putih), Jatinegara,<br>
              Jakarta Barat, DKI Jakarta, ID, 19011</p>
            </v-col>
          </v-row>
        </v-card>

        <v-col class="ps-0">
          <p id="ptitle" class="my-auto text-no-wrap">Your Order</p>
        </v-col>

        <v-card flat id="card1" class="pa-2 mb-10" min-width="350">
          <v-row id="justify-start">
            <v-col>
              <v-row class="d-flex flex-nowrap">
                <v-col style="flex-basis: 30%;" class="d-flex justify-start align-center">
                  <img width="150px" height="auto" src="../assets/checkoutimg.png" alt="">
                </v-col>

                <v-col style="flex-basis: 70%;" class="d-flex flex-column align-start text-no-wrap">
                <p class="mb-0 p3"><b>Goose</b></p><br><br>
                <p class="mb-1 p4">Free Size</p>
                <p class="mb-1 p4">Quantity : 1</p>
                <p class="mb-1 p4">Price : Rp 2,000,000</p>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="d-flex align-start justify-end p5">
              <p>Rp 2,000,000</p>
            </v-col>
          </v-row>

          <div>
          <v-divider class="hr mt-10 mb-5"></v-divider>
          <v-row>
            <v-col>
            <p class="text-left p3 mb-0">Subtotal</p>
            </v-col>

            <v-col >
            <p class="text-right p5 mb-0">Rp 4,000,000</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
            <p class="text-left p3 mb-0">Shipping</p>
            </v-col>

            <v-col >
            <p class="text-right p5 mb-0">Rp 40,000</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
            <p class="text-left p3 mb-0">Promo Code</p>
            <p class="text-left p5 mb-0" style="color:rgba(0, 0, 0, 0.6);">NATSOE10 - 10% OFF does not include shipping</p>
            </v-col>

            <v-col >
            <p class="text-right p5 mb-0">( - Rp 400,000)</p>
            </v-col>
          </v-row>
          <v-divider class="hr my-5"></v-divider>

          <v-row>
            <v-col>
            <p class="text-left p3 mb-0">Total</p>
            </v-col>

            <v-col >
            <p class="text-right p5 mb-0">Rp 4,000,000</p>
            </v-col>
          </v-row>
          
          </div>

        </v-card> 
      </v-col>
          

      <v-col class="col2" cols="12" lg="4">
        <v-card min-width="350" max-width="600" class="bg4 py-7 mb-7 mx-auto">
          <p style="color:black;" class="text-center p2 mb-0">Total</p>
          <p class="text-center p7 mb-0">Rp 3,623,000</p>
        </v-card>
        <v-card flat min-width="350"  max-width="550" class="bg py-5">
          <p id="ptitle" class="my-0 py-4">Select Payment</p>
          <v-radio-group 
          class="pa-0 mt-0 mb-5"
          mandatory
          flat
          solo
          hide-details
          >
          <v-card flat id="card1" class="mx-4 my-3" >
            <v-row>
              <v-col class="col5 ps-5">
                <v-radio class="my-auto" color="blue" value="bni"></v-radio>
                <p class="my-auto mx-2">BNI and Others</p>
              </v-col>

              <v-col class="col6 justify-end pe-5">
                <img width="50px" height="50px" src="../assets/bni2.png">
              </v-col>
            </v-row>
          </v-card>

          <v-divider class="hr"></v-divider>

          <v-card flat id="card1" class="mx-4 my-3">
            <v-row>
              <v-col class="col5 ps-5">
                <v-radio class="my-auto" color="blue" value="bca"></v-radio>
                <p class="my-auto mx-2">BCA</p>
              </v-col>

              <v-col class="col6 justify-end pe-5">
                <img width="50px" height="50px" src="../assets/bca2.png">
              </v-col>
            </v-row>
          </v-card>

          <v-divider class="hr"></v-divider>

          <v-card flat id="card1" class="mx-4 my-3">
            <v-row>
              <v-col class="col5 ps-5">
                <v-radio class="my-auto" color="blue" value="mandiri"></v-radio>
                <p class="my-auto mx-2">Mandiri</p>
              </v-col>

              <v-col class="col6 justify-end pe-5">
                <img class="my-4" width="50px" height="20px" src="../assets/mandiri2.png">
              </v-col>
            </v-row>
          </v-card>

          <v-divider class="hr"></v-divider>

          <v-card flat id="card1" class="mx-4 my-3">
            <v-row>
              <v-col class="col5 ps-5 py-6">
                <v-radio class="my-auto" color="blue" value="credit"></v-radio>
                <p class="my-auto mx-2">Credit Cards</p>
              </v-col>

              <v-col class="col6 justify-end pe-5">
              </v-col>
            </v-row>
          </v-card>

          </v-radio-group>

          <!-- <p id="ptitle2" class="mx-4">Voucher</p>
          <v-card style="background-color: transparent;" flat class="mb-5 mx-4">
          <v-row id="">
            <v-col class="mx-4 col10 px-0">
            <v-text-field 
            dense
            rounded
            hide-details
            filled
            label="Input Voucher Code"
            color="primary"
            class="bg2"
            ></v-text-field>
            </v-col>

            <v-col class="col9 align-center justify-center px-0">
              <v-btn class="py-5 px-5 bg3" color="#00AED6"><p id="p2" class="my-auto" style="font-size: 20px;">Use</p></v-btn>
            </v-col>
          </v-row>
          </v-card> -->
          

          <!-- <p id="ptitle2" class="mx-4">Order Summary</p>
          <v-card class="mx-4 bg3 pa-5">
            <v-row id="row2">
              <v-col class="">
                <p id="p1">Subtotal <br>(1 Item)</p>
              </v-col>

              <v-col class="text-no-wrap">
                <p id="p1"><b>Rp. 1.900.000</b></p>
              </v-col>
            </v-row>

            <v-row id="row2">
              <v-col class="text-no-wrap">
                <p>Shipping Fee</p>
              </v-col>

              <v-col class=" justify-start">
                <p><b>Free</b></p>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row id="row2">
              <v-col class="">
                <p id="p1"><b>Total</b></p>
              </v-col>

              <v-col class="text-no-wrap">
                <p id="p1" style="color: #0F4C85;">Rp. 1.900.000</p>
              </v-col>
            </v-row>
          </v-card> -->

          <div class="d-flex justify-center mx-4 my-5">
          <v-btn @click="process" block class="pa-5 bg3" color="#404040">
          <p class="my-auto p6">Proceed To Payment</p></v-btn>
          </div>
            
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import header from '../components/Header.vue'

export default {
  name: 'account-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      dialog:false,
    }
  },

  methods : {
    process(){
      this.$router.push('/process')
    },
  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:man;
}
.imgLogo{
  display:block;
  margin-left: auto;
  margin-right: auto;
}

.p1{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.08em;
  color: #000000;
}

.p2{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.75);
}

.p3{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.8);
}

.p4{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
}

.p5{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.08em;
  color: #000000;
}

.p6{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.08em;
  color: white;
}

.p7{
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #414141;
}

.hr{
  border:1px solid #C9C9C9;
}

#ptitle{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  color: #414141;
}

#ptitle2{
  font-size:22px;
  font-style: normal;
  font-weight:500;
  color: white;
}

.bg{
  background-color: white;
  border-radius: 20px;
}
.bg2{
  background-color: white;
  border-radius: 15px;
}

.bg3{
  border-radius: 30px;
  text-transform: uppercase;
}

.bg4{
  background: #F5F5F5;
  border-radius: 16px;
}

#row1{
  padding : 5px;
}

#row2{
  padding : 5px 5px 0px 5px;
}

#card1{
  border-radius: 15px;
}

.col1{
  
  flex-direction: column;
  /* flex-basis:60%; */
}

.col2{
  
  flex-direction: row;
  /* flex-basis:40%; */
}

.col3{
  display:flex;
  flex-basis:30%;
  justify-content: flex-start;
}
.col4{
  display:flex;
  flex-basis:70%;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.75);
}
.col5{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis:70%;
}

.col6{
  display:flex;
  flex-basis:30%;
}


</style>