<template>
    <div class="body fill-height">
      <div>
        
        <v-toolbar-items style="padding: 0;" flat solo class="hidden-md-and-down">
          <ul single-line class="navBar">
            <v-row>
            <v-col>
            <div class="d-flex justify-start">
            <!-- <li class="my-auto"><v-btn id="bgcolor" @click="home"  width="40" height="40" text><img width="50" height="34" src="../assets/natsoe logo.png"></v-btn></li>
            <li><v-btn id="fw-lg" @click="about" text><a href="#">About Us</a></v-btn></li>
            <li><v-btn id="fw-lg" @click="contact" text><a href="#">Contact</a></v-btn></li>
            <li><v-btn id="fw-lg" @click="size" text><a href="#">Size Chart</a></v-btn></li>
            <li><v-btn id="fw-lg" @click="hto" text><a href="#">How To Order</a></v-btn></li>
            <li><v-btn id="fw-lg" @click="login" text><a href="#">{{ textauth }}</a></v-btn></li> -->

            <li><v-btn :ripple="false" id="fw-lg" @click="home" plain><a href="#">Home</a></v-btn></li>
            <li><v-btn :ripple="false" id="fw-lg" @click="about" plain><a href="#">About</a></v-btn></li>
            <li><v-btn :ripple="false" id="fw-lg" @click="contact" plain><a href="#">Contact</a></v-btn></li>
            <li><v-btn :ripple="false" id="fw-lg" @click="hto" plain><a href="#">How To Order</a></v-btn></li>

            </div>
            </v-col>

            
            <div class="d-flex justify-end align-center mx-5">
            <li>
              <v-card class="px-3 search" flat min-width="350" > 
                <div>
                  <v-autocomplete
                    v-model="searchs"    
                    @change="onSearch"         
                    label="Find Your Special Product" 
                    class="py-0"
                    color="black"
                    :items="listProductName"
                    hide-details
                    append-outer-icon="mdi-magnify"
                    single-line>
                  </v-autocomplete>
                </div> 
              </v-card>
            </li>
            <!-- <li id="list2"><v-hover v-slot="{hover}"><v-btn id="bgcolor" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="40" height="40" text><img width="38" height="30" src="../assets/lang.png"></v-btn></v-hover></li> 
           -->
            <li id="list2"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="accountauth" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="40" height="40" text><img width="35" height="35" src="../assets/newaccount.png"></v-btn></v-hover></li>    
            <!-- <li id="list2"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="cart" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="40" height="40" text><img width="35" height="35" src="../assets/newcart.png"></v-btn></v-hover></li> -->
          </div>
            
            </v-row>
          </ul>
        </v-toolbar-items>

        <v-toolbar-items style="padding: 0;" flat solo class="d-none d-md-flex d-lg-none">
          <ul class="navBar">
            <v-row>
            <v-col>
            <div class="d-flex justify-start">
            <!-- <li><v-btn id="bgcolor" @click="home"  width="40" height="40" text><img width="50" height="34" src="../assets/natsoe logo.png"></v-btn></li>
            <li><v-btn id="fw-md" @click="about" text><a href="#">About Us</a></v-btn></li>
            <li><v-btn id="fw-md" @click="contact" text><a href="#">Contact</a></v-btn></li>
            <li><v-btn id="fw-md" @click="size" text><a href="#">Size Chart</a></v-btn></li>
            <li><v-btn id="fw-md" @click="hto" text><a href="#">How To Order</a></v-btn></li>
            <li><v-btn id="fw-md" @click="login" text><a href="#">{{ textauth }}</a></v-btn></li> -->

            <li><v-btn :ripple="false" id="fw-md" @click="home" plain><a href="#">Home</a></v-btn></li>
            <li><v-btn :ripple="false" plain id="fw-md" @click="about" text><a href="#">About</a></v-btn></li>
            <li><v-btn :ripple="false" plain id="fw-md" @click="contact" text><a href="#">Contact</a></v-btn></li>
            <li><v-btn :ripple="false" plain id="fw-md" @click="hto" text><a href="#">How To Order</a></v-btn></li>

            </div>
            </v-col>

            
            <div class="d-flex justify-end align-center mx-3">
            <li>
              <v-card class="px-3 search" flat min-width="300" > 
                <div>
                  <v-autocomplete
                    v-model="searchs"
                    @click="onSearch"  
                    label="Find Your Special Product" 
                    class="py-0"
                    :items="listProductName"
                    color="black"
                    hide-details
                    append-outer-icon="mdi-magnify"
                    single-line>
                  </v-autocomplete>
                </div> 
              </v-card>
            </li>
            <!-- <li id="list3"><v-hover v-slot="{hover}"><v-btn class="px-0" id="bgcolor" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="38" height="40" text><img width="35" height="28" src="../assets/lang.png"></v-btn></v-hover></li> 
          -->
            <li id="list3"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="accountauth" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="35" height="40" text><img width="35" height="35" src="../assets/newaccount.png"></v-btn></v-hover></li>    
            <!-- <li id="list3"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="cart" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="35" height="40" text><img width="35" height="35" src="../assets/newcart.png"></v-btn></v-hover></li> -->
           
          </div>
            
            </v-row>
          </ul>
        </v-toolbar-items>

        <v-toolbar-items  style="padding: 0;" flat solo class="hidden-md-and-up">
          
          <ul class="navBar d-flex">

            <v-menu
            open-on-hover
            width="30%"
            offset-y>
              <template v-slot:activator="{ on, attrs }">
              <li><v-btn v-bind="attrs" v-on="on" class="pa-5 me-5" outlined color="black"><v-icon color="black">mdi-menu</v-icon></v-btn></li>
              </template>
              <v-list id="bgcolor1">
                <v-list-item-title>

                <div>
                <li><v-btn block id="fw-xs" @click="home" text><a class="text-left" href="#">Home</a></v-btn></li>
                </div>
                
                <div>
                <li><v-btn block id="fw-xs" @click="about" text><a href="#">About</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="contact" text><a href="#">Contact</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="size" text><a href="#">Size Chart</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="hto" text><a href="#">How To Order</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="login" text><a href="#">{{ textauth }}</a></v-btn></li>
                </div>
                
                </v-list-item-title>
              </v-list>
            </v-menu>

            <v-menu
            open-on-hover
            width="30%"
            offset-y>
            <template v-slot:activator="{ on, attrs }">
            <li><v-btn v-bind="attrs" v-on="on" class="pa-5 me-3" outlined style="color: black;" color="black"  width="45" height="40"><img width="45" height="35" src="../assets/logonat.png"></v-btn></li>
            </template>
            <v-list id="bgcolor1">
              <v-list-item-title class="">
                <div>
                <li><v-btn block id="fw-xs" @click="category" text><a href='#'>All</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="category" text><a href='#'>Bridal</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="category" text><a href='#'>Party</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="category" text><a href='#'>Ready To Wear</a></v-btn></li>
                </div>

                <div>
                <li><v-btn block id="fw-xs" @click="category" text><a href='#'>Customization</a></v-btn></li>
                </div>

              </v-list-item-title>
            </v-list>
            </v-menu>

            <div class="d-flex justify-center align-center">
              <li id="list"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="accountauth" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="40" height="40" outlined><img width="35" height="35" src="../assets/newaccount.png"></v-btn></v-hover></li>    
              <!-- <li id="list"><v-hover v-slot="{hover}"><v-btn id="bgcolor" @click="cart" :style="{'background-color':hover ? '#FFB84C' : '#FFFFFF'}" width="40" height="40" outlined><img width="35" height="35" src="../assets/newcart.png"></v-btn></v-hover></li> -->
            </div>

            <v-card class="px-3 search d-none d-sm-flex d-md-none" flat min-width="300" > 
              <div>
                <v-autocomplete
                  v-model="searchs"
                  @click="onSearch"  
                  label="Find Your Special Product" 
                  class="py-0"
                  :items="listProductName"
                  color="black"
                  hide-details
                  append-outer-icon="mdi-magnify"
                  single-line>
                </v-autocomplete>
              </div> 
            </v-card>
          </ul>
        </v-toolbar-items>
        
        <v-card class="px-3 search hidden-sm-and-up" flat min-width="300" > 
          <div>
            <v-autocomplete
              v-model="searchs"
              @click="onSearch"  
              label="Find Your Special Product" 
              class="py-0"
              :items="listProductName"
              color="black"
              hide-details
              append-outer-icon="mdi-magnify"
              single-line>
            </v-autocomplete>
          </div> 
        </v-card>

        <div class="hidden-md-and-up">
        <img class="imgLogo2" width='450' height='100' src="../assets/logo2.png">
        </div>

        <div class="hidden-sm-and-down">
        <img class="imgLogo" width='450' height='100' src="../assets/logo2.png">
        </div>
        
      </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios';
import constant from '../util/constant';

export default {
  name: 'header-page',

  data (){
    return{
      textauth:"Login / Register",
      searchs:"",
      listProductName:[],
      listProduct:[]


    }
  },
  created(){
    //  this.searchs = localStorage.getItem("search")
     this.checkAuth();
     this.getData()
  

  },
  methods : {
    checkAuth(){
      var userid = localStorage.getItem("userid");
      if(userid != null){
        this.textauth = "Logout";
      }else{
        this.textauth = "Login / Register";
      }
    },
  
    getData(){

      let a = this
 

      axios.get(constant.BASE_URL + "product")
      .then(function (response) {

        let item = response.data.data
        a.listProductName = []

        for(var i = 0; i < item.length; i++){

          a.listProductName.push(item[i].name)
          a.listProduct.push(item[i])
               
        }

      }).catch(function (error) {
        console.log(error);
      })

    },
    home(){
      this.$router.push('/')
    },

    contact(){
      this.$router.push('/contact')
    },

    size(){
      this.$router.push('/size')
    },

    hto(){
      this.$router.push('/how')
    },

    category(){
      this.$router.push('/category')
    },
    onSearch(){
    console.log(this.searchs)
      for(var i = 0; i < this.listProductName.length; i++){
        if(this.searchs === this.listProductName[i]){
            this.$router.push('/detail/'+ this.listProduct[i].id)
            this.$forceUpdate();
        }
      }

      // localStorage.setItem("search", this.searchs)
      
    },

    login(){
      this.$router.push('/login')
    },

    accountauth(){
      var userid = localStorage.getItem("userid");
      if(userid != null){
        this.$router.push('/account')
      }else{
        this.$router.push('/login')
      }

    },

    logout(){
      var userid = localStorage.getItem("userid");
      if(userid != null){
        Swal.fire({
            title: "<h3 style='font-family:ros;'>Continue Logout</h3>",

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "<h3 style='font-family:ros;'>Logout</h3>",
            cancelButtonText: "<h3 style='font-family:ros;'>Cancel</h3>"
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                icon:'success',
                title : "<h3 style='font-family:ros;'>Logout Success</h3>",
                showConfirmButton: false,
              })
              localStorage.clear()
              this.$router.push('/')
              this.checkAuth()
            }
          })
      }else{
        this.$router.push('/login')
      }
    },

    account(){
      this.$router.push('/account')
    },

    cart(){
      this.$router.push('/cart')
    },

    about(){
      this.$router.push('/about')
    },


  },

}

</script>

<style lang="scss" scoped>
.body{
  padding:0px;
  position: relative;
  height: 100%;
  font-family:man;
}

.navBar{
  overflow: hidden;
  padding:10px 5px;
  text-align: left;
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex-grow: 1;
  min-width: 350px;
  
}

#fw-lg{
  font-size: 15px; 
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 20px;
  letter-spacing: 0.08em;
}

#fw-lg::before{
  background-color: transparent;
}

#fw-md{
  font-size: 15px; 
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding: 5px 10px;
  letter-spacing: 0.08em;
}

#fw-md::before{
  background-color: transparent;
}

#fw-xs{
  font-size: 15px; 
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding: 20px 15px;
  justify-content: left;
  letter-spacing: 0.08em;
}

#list{
  padding: 5px 10px;
  overflow: hidden; 
}

#list2{
  padding: 5px 5px;
  overflow: hidden; 
}

#list3{
  padding: 5px 0px;
  overflow: hidden; 
}

#bgcolor1{
  background-color: white;
}

li{
  display: inline;
  text-decoration: none;
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:black;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  text-decoration: none;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;

}

/* a:hover:after{
  width:100%;
} */

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imgLogo2{
  display: block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 450px;
  min-width: 315px;
  height: auto;
  width: 100%;
}

.search{
  margin:5px ;
  padding:5px;
}

</style>