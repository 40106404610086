<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <div>
      <img class="imgLogo my-3" width='250' height='150' src="../assets/logo.jpg">
    </div>

    <v-row  id="margin" class="justify-center align-center my-auto hidden-sm-and-down">
      <v-col  class="col1 px-10">
        
        <p id="ptitle" class="mt-5 mb-3">Shipping Detail</p>

        <v-card id="card1" class="pa-3" outline>
          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Name</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">Aliska</p>
            </v-col>
            
          </v-row >
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Contact</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">08126428418</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Ship To</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">Jalan abcde, no 57.Jawa Barat<br>
              40252,Indonesia.</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Shipping</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">JNE Regular <span style="display: inline-block;"><ul><li><b>FREE</b></li></ul></span></p>
            </v-col>
          </v-row>
        </v-card>

          
        <v-col class="pa-0">
          <p id="ptitle" class="mt-10 mb-3">Product Detail</p>
        </v-col>

        <v-card id="card1" class="pa-5">
          <v-row>
            <v-col class="d-flex justify-center">
              <img width="150px" height="auto" src="../assets/checkoutimg.png" alt="">
            </v-col>

            <v-col class="d-flex flex-column justify-end align-start">
              <p id="p3"><b>Black Blazzer</b></p>
              <p id="p3" style="color:rgba(0, 0, 0, 0.6);">x1</p>
              <p id="p3">Status</p>
            </v-col>

            <v-col class="d-flex flex-column justify-end align-end">
              
              <p id="p2">Rp. 1.900.000</p>
              <p id="p2">On Process</p>
            </v-col>

          </v-row>
        </v-card>

        <v-col class="pa-0">
          <p id="ptitle" class="mt-10 mb-3">Payment Receipt</p>
        </v-col>

        <v-card id="card1" class="pa-3" outlined>
          <v-row class="">
            <v-col class="col5">
              <v-file-input 
              hide-details
              dense
              style="border: none; text-decoration: none;"
              accept="image/*"
              label="Upload Your Payment Receipt Here"
              flat
              solo
              prepend-icon=""></v-file-input>
            </v-col>

            <v-col class="col2 justify-start">
              <v-btn id="button" color="black" class="">
                <v-icon class="ms-3" left large>mdi-cloud-upload-outline</v-icon> 
                <p class="my-auto ms-3">Upload Here</p>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <div class="d-flex justify-end mt-15">
        <v-btn tonal class="py-5 px-10 my-10" id="button" color="black" >Send</v-btn>
        </div>

      </v-col>
    </v-row>

    <v-row class="justify-center align-center my-auto hidden-md-and-up">
      <v-col  class="col1 px-8">
        
        <p id="ptitle" class="mt-5 mb-3 text-no-wrap">Shipping Detail</p>

        <v-card min-width="320" id="card1" class="pa-3 d-flex d-sm-none flex-column" outline>
          <v-row id="row1">
            <v-col class="d-flex flex-column">
              <div>
              <p id="p1" class="bg">Name :</p>
              </div><br>
              <div>
              <p id="p1">Aliska</p>
              </div>
            </v-col>
          </v-row >
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="d-flex flex-column">
              <div>
              <p id="p1" class="bg">Contact :</p>
              </div><br>
              <div>
              <p id="p1">08126428418</p>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="d-flex flex-column">
              <div>
              <p id="p1" class="bg">Ship To :</p>
              </div><br>
              <div>
              <p id="p1">Jalan abcde, no 57.Jawa Barat<br>
              40252,Indonesia.</p>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="d-flex flex-column">
              <div>
              <p id="p1" class="bg">Shipping :</p>
              </div><br>
              <div>
              <p id="p1">JNE Regular <span style="display: inline-block;"><ul><li><b>FREE</b></li></ul></span></p>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card id="card1" class="pa-3 d-none d-sm-flex d-md-none flex-column" outline>
          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Name</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">Aliska</p>
            </v-col>
            
          </v-row >
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Contact</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">08126428418</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Ship To</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">Jalan abcde, no 57.Jawa Barat<br>
              40252,Indonesia.</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row id="row1">
            <v-col class="col3">
              <p id="p1" class="bg">Shipping</p>
            </v-col>
            
            <v-col class="col4">
              <p id="p1">JNE Regular <span style="display: inline-block;"><ul><li><b>FREE</b></li></ul></span></p>
            </v-col>
          </v-row>
        </v-card>

          
        <v-col class="pa-0">
          <p id="ptitle" class="mt-10 mb-3 text-no-wrap">Product Detail</p>
        </v-col>

        <v-card min-width="320" id="card1" class="pa-5">
          <div class="d-flex justify-center mb-5">
            <img width="150px" height="auto" src="../assets/checkoutimg.png" alt="">
          </div>

          <p id="p3" class="my-2 text-center"><b>Black Blazzer</b></p>
          <v-row class="d-none d-sm-flex d-md-none">
            <v-col class="d-flex flex-column justify-end align-start">
              <p id="p3" class="my-2" style="color:rgba(0, 0, 0, 0.6);">x1</p>
              <p id="p3" class="my-2">Status</p>
            </v-col>
            <v-col class="d-flex flex-column justify-end align-end">
              <p id="p2" class="my-2">Rp. 1.900.000</p>
              <p id="p2" class="my-2">On Process</p>
            </v-col>
          </v-row>

          <v-row class="d-flex d-sm-none">
            <v-col class="d-flex flex-column justify-end align-start">
              <p id="p3xs" class="my-2" style="color:rgba(0, 0, 0, 0.6);">x1</p>
              <p id="p3xs" class="my-2">Status</p>
            </v-col>
            <v-col class="d-flex flex-column justify-end align-end">
              <p id="p2xs" class="my-2">Rp. 1.900.000</p>
              <p id="p2xs" class="my-2">On Process</p>
            </v-col>
          </v-row>
        </v-card>

        <v-col class="pa-0">
          <p id="ptitle" class="mt-10 mb-3">Payment Receipt</p>
        </v-col>

        <v-card min-width="320" id="card1" class="pa-3" outlined>
          <v-row class="">
            <v-col class="col5">
              <v-file-input 
              hide-details
              dense
              style="border: none; text-decoration: none;"
              accept="image/*"
              label="Upload Your Payment Receipt Here"
              flat
              solo
              prepend-icon=""></v-file-input>
            </v-col>

            <v-col class="col2 justify-start">
              <v-btn id="button" color="black" class="">
                <v-icon class="ms-3" left large>mdi-cloud-upload-outline</v-icon> 
                <p class="my-auto ms-3">Upload Here</p>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat min-width="320">
        <div class="d-flex justify-end mt-15">
        <v-btn tonal class="py-5 px-10 my-10" id="button" color="black" >Send</v-btn>
        </div>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import header from '../components/Header.vue'

export default {
  name: 'account-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      
    }
  },

  methods : {

  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}
.imgLogo{
  display:block;
  margin-left: auto;
  margin-right: auto;
}

.bg{
  color: rgba(0, 0, 0, 0.6);
}

#button{
  color: white;
  text-transform: capitalize;
  letter-spacing: normal;
  font-size: 20px;
  border-radius: 15px;
}

#ptitle{
  font-size:25px;
  font-style: normal;
  font-weight:bold;
  padding:0px;
}

#margin{
  padding-left: 15vw;
  padding-right: 15vw;

}

#p1{
  margin-bottom: auto;
  margin-top:auto;
  font-size:20px;
  font-weight: 400;
}

#p2{
  color: #0F4C85;
  font-weight: 500;
  font-size:20px
}

#p2xs{
  color: #0F4C85;
  font-weight: 500;
  font-size:15px
}

#p3{
  font-size:20px;
}

#p3xs{
  font-size:15px;
}

#row1{
  padding : 5px;
}

#card1{
  border-radius: 15px;
}

.col1{
  display:flex;
  flex-direction: column;
}

.col2{
  display:flex;
  flex-basis:25%;
}

.col3{
  display:flex;
  flex-basis:30%;
  justify-content: flex-start;
}
.col4{
  display:flex;
  flex-basis:70%;
  justify-content: flex-start;
}
.col5{
  display:flex;
  flex-shrink:0%;
  flex-basis:70%;
  justify-content: flex-start;
}
.col6{
  display:flex;
  flex-basis:20%;
}
.col7{
  display:flex;
  flex-basis:60%;
}


</style>