<template>
  <div class="body">
    <div>
      <Header></Header>
    </div>

    <!-- <div class="hidden-md-and-up">
			<img class="imgLogo2" width='450' height='100' src="../assets/logo2.png">
		</div>

		<div class="hidden-sm-and-down">
			<img class="imgLogo" width='450' height='100' src="../assets/logo2.png">
		</div> -->

		<div class="hidden-sm-and-down">
			<v-toolbar flat class="d-flex justify-center my-3">
				<v-toolbar-items flat class="hidden-md-and-down">
					<ul class="navBar2">
          <li class="li-lg"><v-btn id="fs-lg" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('culture')" text><a href='#'>Culture</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>

				<v-toolbar-items flat class="d-none d-md-flex d-lg-none">
					<ul class="navBar2">
          <li class="li-md"><v-btn id="fs-md" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('culture')" text><a href='#'>Ready To Wear</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>
        
			</v-toolbar>
		</div>

    <div class="d-flex flex-row">
    <v-navigation-drawer
    floating
    permanent
    left
    style="min-width: 300px;"
    height="100vh"
    class="pa-15 hidden-sm-and-down"
    >
    <div class="d-flex flex-column">
    <li><a href="#fhome2d"><v-btn plain class="btndrawer" >Indonesia</v-btn></a></li>
    <li><a href="#fhome2"><v-btn plain class="btndrawer" >International</v-btn></a></li>
    <li><a href="#"><v-btn plain class="btndrawer" >Custom Order</v-btn></a></li>
    </div>
    </v-navigation-drawer>


    <div class="mx-auto justify-center">

    <div class="mt-15">
    <p id="fhome" class="">How To Order</p>
    </div>

    <div class="mt-8 d-flex justify-center ">
      
      <v-card  flat class="px-5 ">
        <p id="fhome2" class=""><span style="font-weight:bold;">Shop our collection through Shopee, Tokopedia, Blibli, and Zalora</span><br><br>
        1. Select Product<br>
        From our website or your selected third-party app, select the product you are interested in by clicking the <br>
        photo for a complete description and close-up images. If you are browsing through our website, you will <br>
        still be asked to purchase through our third-party app.<br><br>
        2. Place your order<br>
        If you decide to finalize your order, place your order. Please note that product availability is not <br>
        guaranteed before you check out.<br><br>
        3. Finish Shopping<br>
        Finish your payment, your order will be delivered in 2-3 working days.<br>
        </p>

        <div>
          <hr class=" my-12 ">
        </div>
      </v-card>
      
    </div>

    <div class="d-flex justify-center mb-15">
      
      <v-card  flat class="ps-5 pe-12">
        <p id="fhome2d" class=""><span style="font-weight:bold;">Dapatkan koleksi kami melalui Shopee, Tokopedia, Blibli, dan Zalora</span><br><br>
        1. Pilih Produk<br>
        Dari situs web kami atau aplikasi pihak ketiga pilihan Anda, pilih produk yang Anda minati dengan <br>
        mengeklik foto untuk mendapatkan deskripsi lengkap dan gambar close-up. Jika Anda menjelajahi situs <br>
        web kami, Anda tetap akan diminta untuk membeli melalui aplikasi pihak ketiga kami.<br><br>
        2. Tempatkan pesanan Anda<br>
        Jika Anda memutuskan untuk menyelesaikan pesanan Anda, lakukan pemesanan. Harap diperhatikan <br>
        bahwa ketersediaan produk tidak dijamin sebelum Anda check out.<br><br>
        3. Selesai Berbelanja<br>
        Selesaikan pembayaran Anda, pesanan Anda akan dikirim dalam 2-3 hari kerja.<br>
        </p>
      </v-card>
      
    </div>

    <div class="mb-12">
      <v-card flat min-width="330" class="mx-4">
        <div>
        <p class="pa-3 ma-0 text-center" id="fhome">Lookbook</p>
        </div>

        <div>
        <p class="ma-0 text-center" id="fhome2">#dressedinNataliaSoetjipto to get featured</p>
        </div>
      </v-card>
    </div>

    <div class="">
      <v-row class="mx-5 mb-15  justify-center">
        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150"> 
          <v-img width="100%" max-height="350" min-height="150" src="../assets/lookbook1.png">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150" src="../assets/lookbook2.png">
          </v-img>
        </v-card>
        </v-col>

        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150" src="../assets/lookbook3.png">
          </v-img>
        </v-card>
        </v-col>
          
        <v-col cols="6" sm="6" md="5" lg="2">
        <v-card flat class="mx-auto" max-width="350" min-width="150">
          <v-img width="100%" max-height="350" min-height="150" src="../assets/lookbook4.png">
          </v-img>
        </v-card>
        </v-col>
      </v-row>
    </div>
    
    </div>
    
    <v-navigation-drawer
    floating
    right
    width="300"
    height="100vh"
    class="pa-15 hidden-sm-and-down"
    >
    <div class="d-flex flex-column">
    <p></p>
    </div>
    </v-navigation-drawer>
    </div>

    

  <div>
    <Footer></Footer>
  </div>

  </div>
</template>

<script>
import header from '../components/Header.vue'
import footer from '../components/Footer.vue'

export default {
  name: 'how-page',
  components:{
    'Header':header,
    'Footer':footer
  },

  data (){
    return{
      
    }
  },

  methods : {
    category(type){
      this.$router.push('/category/' + type)
    },
  },

  created(){

  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:man;
  scroll-behavior: smooth !important;
}

.btndrawer{
  cursor: pointer;
  font-family:man;
  font-size: 16px;
  color: black;
  text-transform: capitalize;
}

.hr{
 margin-left:15%;
 margin-right:15%;
}

#fhome{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 64px;
  color: rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  text-align:center;
}

#fhome2{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-line;
}

#fhome2d{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-line;
}

#fhome3{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.08em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.75);
}

#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imgLogo2{
  display: block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 450px;
  min-width: 335px;
  height: auto;
  width: 100%;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: black;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

a{
  text-decoration: none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

/* .imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
}

.card1{
  background: #D9D9D9;
  border-radius: 30px;
  padding: 15px 15px;
  line-height: 30px;
}

#htitle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

#htitle2{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
}

#padding{
  margin-top:15vh;
}

#h1{
  padding: 15px 10px;
}

#h1xs{
  padding: 15px 5px;
  font-size:25px;
}

#p1{
  padding:15px 10px;
}

#p1xs{
  padding:15px 5px;
  font-size: 15px;
} */

@media only screen and (max-width:960px){
#fhome{
  font-size: 45px;
}

#fhome2{
  font-size: 15px;
}

#fhome2d{
  font-size: 15px;
}



}

</style>