<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

		<!-- <div class="hidden-md-and-up">
			<img class="imgLogo2" width='450' height='100' src="../assets/logo2.png">
		</div>

		<div class="hidden-sm-and-down">
			<img class="imgLogo" width='450' height='100' src="../assets/logo2.png">
		</div> -->

		<div class="hidden-sm-and-down">
      <v-toolbar flat class="d-flex justify-center my-3">
				<v-toolbar-items flat class="hidden-md-and-down">
					<ul class="navBar2">
          <li class="li-lg"><v-btn id="fs-lg" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('culture')" text><a href='#'>Culture</a></v-btn></li>
          <li class="li-lg"><v-btn id="fs-lg" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>

				<v-toolbar-items flat class="d-none d-md-flex d-lg-none">
					<ul class="navBar2">
          <li class="li-md"><v-btn id="fs-md" @click="category('all')" text><a href='#'>All</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('bridal')" text><a href='#'>Bridal</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('party')" text><a href='#'>Party</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('culture')" text><a href='#'>Ready To Wear</a></v-btn></li>
          <li class="li-md"><v-btn id="fs-md" @click="category('customization')" text><a href='#'>Customization</a></v-btn></li>
					</ul>
				</v-toolbar-items>
        
			</v-toolbar>
		</div>

    <div class="mt-15">
      <v-card flat>
        <p id="fhome">Contact Us</p>
        <p id="fhome2">We would love to hear from you! <br class="hidden-md-and-up">Our bridal experts <br class="hidden-sm-and-up">are here to help you.</p>
      </v-card>
    </div>

    <div>
      <v-row class="justify-center">
        <v-col cols="12" sm="10" md="6" lg="5">
          <v-card flat class="card text-center mt-13 mx-3" min-width="350">
            <div class="pt-13 pb-8">
              <v-img class="mx-auto" src="../assets/iglogo.png" width="60" height="60"></v-img>
            </div>
            <p id="fhome2" style="font-weight: bold;" >DM ON INSTAGRAM</p>
            <p class="mb-8" id="fhome3">Send us a direct message <br class="hidden-sm-and-up"> on Instagram and <br>
            we'll get back to you.<br><br>
            Monday to Saturday <br class="hidden-sm-and-up">09.00 - 17.00 (GMT+7)</p>
            <v-btn  @click="goInstagram" class="btn mb-12" color="#404040">
              <p class="my-auto">OPEN INSTAGRAM</p>
            </v-btn>
          </v-card>
        </v-col>

        <v-col cols="12" sm="10" md="6" lg="5">
          <v-card flat class="card text-center mt-13 mx-3" min-width="350">
            <div class="pt-13 pb-8">
              <v-img class="mx-auto" src="../assets/walogos.png" width="60" height="60"></v-img>
            </div>
            <p id="fhome2" style="font-weight: bold;">CHAT ON WHATSAPP</p>
            <p class="mb-8" id="fhome3">Talk to our bridal experts <br class="hidden-sm-and-up"> in real time on <br>
            WhatsApp.<br><br>
            Monday to Saturday <br class="hidden-sm-and-up"> 09.00 - 17.00 (GMT+7)<br></p>
            <v-btn @click="contact" class="btn mb-12" color="#404040">
              <p class="my-auto">OPEN WHATSAPP</p>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="mt-15">
      <v-card flat>
        <p id="fhome2">or visit</p>
        <p id="fhomes">Our Boutique</p>
      </v-card>
    </div>

    <div class="mt-13">
      <v-row class="mb-15 justify-center">
          <v-col class="" cols="12" sm="10" md="7" lg="7">
            <v-card flat class="ms-auto px-5" max-width="950" min-width="350">
              <v-img class="" width="100%" min-height="500" src="../assets/maps.png"></v-img>
            </v-card>
          </v-col>

          <v-col class="d-flex align-center" cols="12" sm="10" md="5" lg="3">
            <v-card flat id="hcard2" class="ms-5" min-width="365" max-width="450">
              <div>
              <p class="pa-3 ma-0 text-no-wrap" id="fhome4">Natalia Soetjipto</p>
              </div>

              <div>
              <p class="pa-3 ma-0 text-justify " id="fhome2">{{ homeSettingsdata.address }},<br>
              Babatan, Kec. Wiyung, Kota <br>
              SBY, Jawa Timur 60213 <br><br>

              Monday - Saturday<br>
              09.00 - 17.00 (GMT+7)<br><br></p>
              </div>

            <v-btn @click="contact" class="btn mb-12" color="#404040">
              <p class="my-auto">MAKE AN APPOINTMENT</p>
            </v-btn>
              
            </v-card>
          </v-col>
      </v-row>
    </div>



    <div>
      <Footer></Footer>
    </div>

  </div>
</template>

<script>
import header from '../components/Header.vue'
import footer from '../components/Footer.vue'
import axios from 'axios';
import constant from '@/util/constant';

export default {
  name: 'account-page',
  components:{
    'Header':header,
    'Footer':footer
  },

  data (){
    return{
      firstName: "",
      lastName: "",
      email:"",
      phone:"",
      problem:"", 
      homeSettingsdata:{},
    }
  },

  methods : {
		category(type){
      this.$router.push('/category/' + type)
    },
    getHomeSettings(){

      let a = this

      axios.get(constant.BASE_URL + "settingshome")
      .then(function (response) {
        console.log(response)
        a.homeSettingsdata = response.data.data[0]

      }).catch(function (error) {
        console.log(error);

      })

    },


    contact(){
      window.open("https://wa.me/6285320007699", '_blank').focus();
    },
    goInstagram(){
      window.open("https://www.instagram.com/nataliasoetjipto_official/?hl=en", '_blank').focus();
    }

  },

  created(){
    this.getHomeSettings()
  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:man;
}

.card{
  background: #F5F5F5;
  border-radius: 16px;
}

.btn{
  border-radius: 30px;
  padding: 8px 24px;
  color: white;
  font-size: 18px;
  letter-spacing: 0.08em;
  font-weight: bold;
}

#fhome{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  white-space: nowrap;
}

#fhomes{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 54px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  white-space: nowrap;
}

#fhome2{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: #404040;
  text-align: center;
  
}

#fhome3{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.04em;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: nowrap;
}

#fhome4{
  font-family: lor;
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  color: #414141;
  line-height: 40px;
  white-space: nowrap;
}

#fs-lg{
  font-size: 18px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

#fs-md{
  font-size: 15px;
  background-color: white;
  padding: 20px 15px;
  border-radius: 20px;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
}

.imgLogo{
  display:block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.imgLogo2{
  display: block;
  padding:5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 50px;
  max-width: 450px;
  min-width: 335px;
  height: auto;
  width: 100%;
}

.navBar2{
  overflow: hidden;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  text-align: center;
  color: black;
}

.li-lg{
  padding: 5px 20px;
}

.li-md{
  padding: 5px 10px;
}

.li-xs{
  padding: 5px 20px;
}

li{
  display: inline; 
}

li a{
  color:black;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 2px;
  width:0;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

#button{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
}

#button1{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
  background-color: black;
}

#name{
  margin-top: 2vh;
}

.mb1{
  margin-bottom: 10vh;
}

#h1{
  letter-spacing: 1vh;
  font-size: 35px;
  color: white;
}

#h2{
  font-size: 35px;
  font-weight:400;
}

#h3{
  font-size: 35px;
  font-weight: 900;
}

#h4{
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  /* font-family: inter; */
  color: rgba(0, 0, 0, 0.6);
}

#h4xs{
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  /* font-family: inter; */
  color: rgba(0, 0, 0, 0.6);
}

#border{
  border-radius: 10px;
}

.bg{
  background-image: url(../assets/contactimg.png);
}

.col1{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0%;
  flex-basis:40%;
  height: 105vh;
  min-width: 320px;
}

.col2{
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis:60%;
  height: 105vh;
  padding:10vh 10vw 10vh 5vw;
}

.col3{
  flex-grow:1;
}

.col4{
  display: inline;
}

/* .navBar{
  font-size: 30px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.82) ;
  color: black;
}

ul{
  padding:0px;
}

li{
  display: inline;
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-size: 20px;
}

li a{
  color:white;
  text-decoration:none;
} */

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 3px;
  width:0;
  left: 0;
  bottom: -4px;
  transition: 0.3s ease;
} */

/* a:hover{
  color: #FFB84C;
} */

/* a:hover:after{
  width:100%;
} */

@media only screen and (max-height:768px){
  .col1{
    height:115vh;
    
  }

  .col2{
    padding: 5vh 10vw 10vh 5vw;
  }

}


</style>