<template>
  <div class="body fill-height">
    <div>
      <Header></Header>
    </div>

    <div class="my-auto" style="padding: 50px;"> 

      <h1 style="font-family: lor;  font-style: italic; font-size: 38px;">My Account</h1>

      <div class="d-flex">
        <p>Welcome back {{ name }} ({{ email }})</p>
        <p @click="logout" style="margin-left: 20px; cursor: pointer; font-weight: bold;">Logout</p>
      </div>

      <v-btn v-if="isAdmin" @click="admin">go to admin page</v-btn>
          
    </div>

    <div>
			<Footer></Footer>
		</div>

  </div>
</template>

<script>
import header from '../components/Header.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import constant from '@/util/constant'
import footer from '../components/Footer.vue'

export default {
  name: 'account-page',
  components:{
    'Header':header,
    'Footer':footer
  },

  data (){
    return{
      name:"",
      email:"",
      password:"",
      role:"",
      isAdmin:false
      
    }
  },

  methods : {
    accountPass(){
      this.$router.push('/accountpass')
    },
    admin(){
      this.$router.push('/admin')
    },
    account(){
      this.$router.push('/account')
    },
    myOrder(){
      this.$router.push('/myorder')
    },
    logout(){
      Swal.fire({
            title: "<h3 style='font-family:ros;'>Continue Logout</h3>",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "<h3 style='font-family:ros;'>Logout</h3>",
            cancelButtonText: "<h3 style='font-family:ros;'>Cancel</h3>"
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                icon:'success',
                title : "<h3 style='font-family:ros;'>Logout Success</h3>",
                showConfirmButton: false,
              })
              localStorage.clear()
              this.$router.push('/')
              this.checkAuth()
            }
          })

    },

    getData(){


      let a = this
      var userid = localStorage.getItem("userid");

      axios.get(constant.BASE_URL + 'account/' + userid).then(response => {
      console.log(response)
      let item = response.data.data[0]
      a.name =  item.name
      a.email = item.email
      a.password = item.password
      a.role = item.role
      
      }).catch(function(error) {
        console.log(error)
      })
    },
    
    updateAccount(){
      
      let a = this
      var userid = localStorage.getItem("userid");
      axios.put(constant.BASE_URL + 'account', {
              
        id : userid,
        firstname : a.firstName,
        lastname : a.lastName,
        email : a.email,
        phone : a.phone,
        address : a.address, 
        password : a.password,
        role : a.role,

      }).then(response => {
    
      Swal.fire({
      icon: 'success',
      title: "<h3 style='font-family:ros;'>Data Diperbarui</h3>",
      })
      console.log(response)
      a.getData()
      }).catch(function(error) {
        console.log(error)
        Swal.fire({
        icon: 'error',
        title: "<h3 style='font-family:ros;'>Gagal Diperbarui</h3>",
        })
      })
    },
    
    

  },

  created(){
    let userRole = localStorage.getItem("role")

    if(userRole === "admin"){
      this.isAdmin = true
    }
    this.getData()
  }


}

</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

#button{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
  background-color: black;
}

#button1{
  text-transform: capitalize;
  /* font-family: inter; */
  font-size: 20px;
  padding: 2vh 4vw;
  border-radius: 20px;
}

#name{
  margin-top: 5vh;
}

.bg{
  background-color: #D9D9D9;
}

.col1{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0%;
  flex-basis:25%;
  height:100vh;
}

.col2{
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis:75%;
  height:100vh;
  padding:10vh 10vw;
}

.col3{
  flex-grow:1;
}

.navBar{
  /* font-family: inter; */
  font-size: 30px;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  /* background-color: rgba(0, 0, 0, 0.82) ; */
  color: black;
}

ul{
  padding:0px;
}

li{
  display: block;
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-size: 20px;
}

li a{
  color: white;
  text-decoration:none;
}

/* a:after{
  content: "";
  position: absolute;
  background-color:white;
  height: 3px;
  width:0;
  left: 0;
  bottom: -4px;
  transition: 0.3s ease;
} */

a:hover{
  color: #FFB84C;
}

/* a:hover:after{
  width:100%;
} */

@media only screen and (max-height:768px){
  .col1{
    height:115vh; 
  }
}

</style>