import Vue from 'vue'
import Router from 'vue-router'

import Account from '../views/Account.vue'
import About from '../views/About.vue'
import AccountPass from '../views/AccountPass.vue'
import Cart from '../views/Cart.vue'
import Category from '../views/Category.vue'
import Checkout from '../views/Checkout.vue'
import Contact from '../views/Contact.vue'
import Detail from '../views/Detail.vue'
import Home from '../views/Home.vue'
import How from '../views/How.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Size from '../views/Size.vue'
import Payment from '../views/Payment.vue'
import Process from '../views/Process.vue'
import Success from '../views/Success.vue'
import MyOrder from '../views/MyOrder.vue'
import Admin from '../views/Admin.vue'



Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path : '/',
            name:'Home',
            component: Home,
            props:true
        },
        {
            path : '/account',
            name:'Account',
            component: Account
        },
        {
            path : '/about',
            name:'about',
            component: About
        },
        {
            path : '/accountpass',
            name:'AccountPass',
            component: AccountPass
        },
        {
            path : '/cart',
            name:'Cart',
            component: Cart
        },
        {
            path : '/category/:type',
            name:'Category',
            component: Category,
            props:true
        },
        {
            path : '/checkout',
            name:'Checkout',
            component: Checkout
        },
        {
            path : '/contact',
            name:'Contact',
            component: Contact
        },
        {
            path : '/detail/:id',
            name:'Detail',
            component: Detail,
            props:true
        },
        {
            path : '/how',
            name:'How',
            component: How
        },
        {
            path : '/login',
            name:'Login',
            component: Login
        },
        {
            path : '/register',
            name:'Register',
            component: Register
        },
        {
            path : '/size',
            name:'Size',
            component: Size
        },
        {
            path : '/payment',
            name:'Payment',
            component: Payment
        },
        {
            path : '/process',
            name:'Process',
            component: Process
        },
        {
            path : '/success',
            name:'Success',
            component: Success
        },
        {
            path: '/myorder',
            name: 'MyOrder',
            component:MyOrder
        },
        {
            path: '/admin',
            name: 'Admin',
            component:Admin
        }
    
    ]
})
