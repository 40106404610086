<template>
  <div class="body fill=height">

      <Header></Header>

      <v-row class="flex-row justify-center hidden-xs mnav" style="margin-top: 20px;">
        <p @click="getData('newarival',1)" class="btndrawer btntype">ALL</p>
        <p @click="selectType('bridal')" style="margin-left: 40px;" class="btndrawer btntype">BRIDAL</p>
        <p @click="selectType('party')" style="margin-left: 40px;" class="btndrawer btntype">PARTY</p>
        <p @click="selectType('culture')" style="margin-left: 40px;" class="btndrawer btntype">CULTURE</p>
        <p @click="selectType('customization')" style="margin-left: 40px;" class="btndrawer btntype">CUSTOMIZATION</p>
      </v-row>

      <div class="d-flex flex-row mb-12">

      <v-navigation-drawer
        class="navdrawer hidden-sm-and-down"    
        :elevation="0" 
        permanent
        floating
        left
      >

      <div class="d-flex flex-column mb-6 bg-surface-variant text-no-wrap">

        <p @click="change('newarival')" class="ma-2 pa-2 btndrawer">GODDESS SERIES</p>

        <p  @click="change('sale')"  class="ma-2 pa-2 btndrawer">CLASSIC BRIDE</p>

        <p  @click="change('woman')"  class="ma-2 pa-2 btndrawer">BATIK DRESS</p>

        <p  @click="change('streetwear')"  class="ma-2 pa-2 btndrawer">MODERN PARTY</p>

        <p  @click="change('fashion')"  class="ma-2 pa-2 btndrawer">ORIENTAL LOOK</p>

        <p  @click="change('modest')"  class="ma-2 pa-2 btndrawer">STREET WEAR</p>

      </div>

      </v-navigation-drawer>
     
      <template>

        <v-dialog
          v-model="dialogLoading"
          hide-overlay
          
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text>
              Please wait
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-container class="">
         
          <!-- <div class="d-flex flex-column">
            <img class="mx-auto imglogo" style="max-width: 450px;" src="../assets/logo2.png" />
          </div> -->
              
          <div class="content">

            <v-btn
              class="tgldrawer"
              dark
              style="margin-top: 20px;"
              @click.stop="drawer = !drawer">
              Select Category
            </v-btn>

          <v-row class="d-none d-md-flex d-lg-none flex-row " style="margin-left: 50px; margin-top: 20px;">
            <p @click="getData('newarival',1)" class="btndrawer btntype">ALL</p>
            <p @click="selectType('bridal')" style="margin-left: 40px;" class="btndrawer btntype">BRIDAL</p>
            <p @click="selectType('party')" style="margin-left: 40px;" class="btndrawer btntype">PARTY</p>
            <p @click="selectType('culture')" style="margin-left: 40px;" class="btndrawer btntype">CULTURE</p>
            <p @click="selectType('customization')" style="margin-left: 40px;" class="btndrawer btntype">CUSTOMIZATION</p>
          </v-row>

              <v-container  >

              <v-row dense>

              <v-col     
                v-for="card in products"
                :cols="4"
                :key="card.id">

              <div
                class="carditem "
                @click="detail(card.id)"
                style="margin:30px;">

              <v-img
                :src="card.listphotos"
                height="300px"
                width="250px">
              </v-img>

              <v-card-title class="text-white" v-text="card.name"></v-card-title>
              <v-card-title style="font-family:man;color: rgba(0, 0, 0, 0.5); margin-top: -25px;">{{ formatRupiah(card.price) }}</v-card-title>
            
              <div style="padding-right: 20px; color: #0F4C85;" class="d-flex flex-row mb-12">
  
              </div>
              </div>
              </v-col>
             </v-row>

            </v-container>
                     
      </div>
        </v-container>

        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary>

        <div class="d-flex flex-column mb-6 bg-surface-variant">
      
          <p @click="change('newarival')" class="ma-2 pa-2 btndrawer">GODDESS SERIES</p>

          <p  @click="change('sale')"  class="ma-2 pa-2 btndrawer">CLASSIC BRIDE</p>

          <p  @click="change('woman')"  class="ma-2 pa-2 btndrawer">BATIK DRESS</p>

          <p  @click="change('streetwear')"  class="ma-2 pa-2 btndrawer">MODERN PARTY</p>

          <p  @click="change('fashion')"  class="ma-2 pa-2 btndrawer">ORIENTAL LOOK</p>

          <p  @click="change('modest')"  class="ma-2 pa-2 btndrawer">STREET WEAR</p>

          </div>
    
        </v-navigation-drawer>
      
      </template>

      <v-navigation-drawer
      floating
      right
      class="pa-15 navdrawer2 hidden-lg-and-down"
      style="background-color: transparent;"
      >
      <div class="d-flex flex-column">
      <p></p>
      </div>
      </v-navigation-drawer>

  </div>

  

  <div class="d-flex flex-row-reverse" style="margin: 80px;">
    <v-pagination
      @input="updatePage"
      class="pagination"
      v-model="page"
      :length="lengthpage"
      rounded="0">
    </v-pagination>    
  </div>

  <Footer></Footer>
  </div>
</template>

<script>
import footer from '../components/Footer.vue';
import header from '../components/Header.vue';
import axios from 'axios';
import constant from '../util/constant';
import Swal from 'sweetalert2'

export default {
  name: 'category-page',
  components : {
      'Header':header,
      'Footer':footer
    },

    data (){
    return{
      drawer: null,
      products:[],
      page: 1,
      lengthpage :1,
      categoryType:"",
      dialogLoading: false
    }
  },
  created(){
    let doctype = this.$route.params.type
    console.log(doctype)
    window.scrollTo(0, 0);
    if(doctype == "all"){
      this.getData("newarival",1)
    }else{
       this.selectType(doctype)
    }
 
  },
  methods: {

    detail(id){
    this.$router.push('/detail/' + id)
    },
    change(type){
      this.drawer = false
      this.page = 1
       this.getData(type,1)
    },

    selectType(keyword){

      this.dialogLoading = true
      let a = this
      this.categoryType = keyword

      axios.get(constant.BASE_URL + "product")
      .then(function (response) {

        a.products = [];
        let newdata = response.data.data;
        let filtereddata = newdata.filter(item => item.category === keyword);

        for(var i = 0; i < filtereddata.length; i++){

          var item = filtereddata[i]

          let listimg = item.listphotos.split(',');

          if(a.products.length < 9 ){

          a.products.push({
            "id":item.id,
            "name":item.name,
            "description": item.description,
            "price": item.price,
            "listphotos":listimg[0],
            "color":item.color,
            "size":item.size,
            "qty":item.qty,
            "category":item.category
          })
        }
      }

        a.saveproducts = a.products
        a.dialogLoading = false  
        window.scrollTo(0, 0);
       
      }).catch(function (error) {
        console.log(error);
        a.dialogLoading = false
        window.scrollTo(0, 0);
      })

    },
    formatRupiah(number) {
      const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          });

          return formatter.format(number);
},
search(inputtext){
  console.log(inputtext)
  this.products = []

  if(inputtext == ""){
    this.products = this.saveproducts
  }else{
    for(let i = 0; this.saveproducts.length; i++){
   if(this.saveproducts[i].name.includes(inputtext)){
    this.products.push(this.saveproducts[i])
   }
}
  }
},
addCart(card){

  var userid = localStorage.getItem("userid");
      if(userid == null){
        this.$router.push('/login')
      }else{

  
axios.post( constant.BASE_URL + 'cart', 
    {
      userid:userid,
      productid:card.id,
      qty:1,
      size:card.size,
      totalprice :card.price
    })
  .then(function (response) {

    console.log(response);

    Swal.fire({
      icon: 'success',
      title: "<h3 style='font-family:ros;'>Berhasil ditambahkan</h3>",
      showConfirmButton: false,
    })
           
      }).catch(function (error) {
        
        Swal.fire({
          icon: 'error',
          title: error
        })
        console.log(error);
      });
            
      }

},
  updatePage(pageindex){
    this.page = pageindex
    this.getData(this.categoryType,pageindex)
  },
  getLengthPage(totallength){

    this.lengthpage = 0
    for(var i = 0; i < totallength; i++){
      if(i %9 === 0){
      this.lengthpage++
      }
    }

  },
    getData(keyword, page){
      this.dialogLoading = true

      let a = this
      this.categoryType = keyword

      let pageCount = (page - 1) * 9

      axios.get(constant.BASE_URL + "product")
      .then(function (response) {

        a.products = [];
        let newdata = response.data.data; 
        let filtereddata = newdata.filter(item => item.category === keyword);
        a.getLengthPage(filtereddata.length)

        for(var i = pageCount; i < filtereddata.length; i++){
         
          var item = filtereddata[i]

          let listimg = item.listphotos.split(',');

          if(a.products.length < 9 ){

          a.products.push({
            "id":item.id,
            "name":item.name,
            "description": item.description,
            "price": item.price,
            "listphotos":listimg[0],
            "color":item.color,
            "size":item.size,
            "qty":item.qty,
            "category":item.category
          })
        }
      }
      a.saveproducts = a.products
      a.dialogLoading = false
      window.scrollTo(0, 0);
      })
      .catch(function (error) {        
        console.log(error);
        a.dialogLoading = false
        window.scrollTo(0, 0);
      })

      

    }
  }
}

</script>

<style lang="scss" scoped>
.body{
}

.content{
  padding: 8px;
}

.paddingnav{
  padding-top: 40px;
}

.searcbar{
  padding:12px;
  width: 280px;
  height: 20px;
}

.search{
  margin:5px 15px;
  padding:5px;
  border-radius:20px;
  border: 2px solid;
 
}

.mnav{
  display : flex;
  margin-left:0px;
}

.tgldrawer{
  display: none;
}
.carditem{
  width: 250px;
  cursor: pointer;
}

.searcbar::before{
  border-style: none !important;
}

.btndrawer{
  cursor: pointer;
  font-family:man;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
}

.navdrawer {
  min-width: 200px;
  max-width: 400px; 
  padding-top: 100px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 10px;
}

.navdrawer2 {
  min-width: 200px;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 10px;
}

@media only screen and (max-width: 1920px) {
  .navdrawer{
    min-width:200px;
    padding-right:40px;
  }

   .navdrawer2{
    min-width:100px;
    max-width:100px
  }

}

@media only screen and (max-width: 1264px) {
  .navdrawer{
    min-width:200px;
    padding-right: 20px;
  }

  .mnav{
    display: none;
    margin-left:100px;
}

}

@media only screen and (max-width: 959px) {
  .mnav{
    display:flex;
    margin-left:0px;
}

}

@media only screen and (max-width: 600px) {

.content{
  padding: 0px;
}

.btntype{
  display: none;
}

.imglogo{
  max-width: 250px !important;
}

.search{
  width: 250px !important;
}

.pagination{
  margin-bottom: 50px;
}

.tgldrawer{
  display: block;
}

.navdrawer {
  display: none;
}
.carditem{
  margin: 8px !important;
}

}

</style>