<template>
    <div class="body">
      <div>
      <Header></Header>
      </div>

      <div class="d-flex justify-center mt-10 mb-5">
        <h1>SIZE CHART</h1>
      </div>

      <div class="d-flex justify-center align-center mb-10">
        <v-card flat class="d-flex justify-center"><img cover width="90%" height="auto" src="../assets/sizechart.png"></v-card>
      </div>


    </div>
</template>

<script>
import header from '../components/Header.vue'

export default {
  name: 'size-page',
  components:{
    'Header':header,
  },

  data (){
    return{
      
    }
  },

  methods : {

  },

  created(){

  }


}


</script>

<style scoped>
.body{
  padding:0px;
  margin: 0px;
  position: relative;
  height: 100%;
  width:100%;
  font-family:ros;
}

</style>